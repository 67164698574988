import React from 'react';
import styles from './NoAccess.module.css'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
export default function NoAccess() {
  return (
    
    <div className={styles.container}>
      <LogoutUser/>
      <i className={'material-icons ' + styles.lockIcon}>lock</i>
      <p className={styles.message}>Sorry, you don't have access to this</p>
    </div>
  )
}