import React, { useState, useContext } from "react"
import { useGet, logEvent } from "../../utils/_hooks"
import VirtualList from "../../components/VirtualList/VirtualList"
import NewMemberDialog from "../../components/NewMemberDialog/NewMemberDialog"
import styles from "./Groupinfo.module.css"
import parseName from "../../utils/parseName"
import axios from "axios"
import NoAccess from '../NoAccess/NoAccess'
import { AuthContext } from "../../context/AuthContext"
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import { CircularProgress } from "@rmwc/circular-progress"
import "@rmwc/circular-progress/circular-progress.css"
import { Chip } from '@rmwc/chip'
import '@rmwc/chip/styles';
import Avatar from '../../components/Avatar'
import { List, CollapsibleList, SimpleListItem } from "@rmwc/list"
import '@material/list/dist/mdc.list.css'
import '@rmwc/list/collapsible-list.css'

import { Button } from "@rmwc/button"
import "@material/button/dist/mdc.button.css"

import { Icon } from "@rmwc/icon"
import "@rmwc/icon/icon.css"

import { Tooltip } from "@rmwc/tooltip"
import "@rmwc/tooltip/tooltip.css"

import { TabBar, Tab } from "@rmwc/tabs"
import "@rmwc/tabs/styles"

import {SnackbarContext} from '../../context/SnackbarContext'

const USER = "USER"
const GROUP = "GROUP"
const MEMBEROF = "MEMBEROF"


export default function GroupInfo({ group_id, location, navigate }) {
  const { user } = useContext(AuthContext)
  const [value, setValue] = useState("")
  const [addList, setAddList] = useState([])
  const [showModal, setShowModal] = useState(null)
  const [posting, setPosting] = useState(false)
  const [addingError, setAddingError] = useState(false)
  const [delUser, setDelUser] = useState("")
  const [delGroup, setDelGroup] = useState("")
  const [delMemberOf, setDelMemberOf] = useState("")
  const [delRequest, setDelRequest] = useState("")
  const [addRequest, setAddRequest] = useState("")
  const [activeTab, setActiveTab] = useState(0)
  const [suggestions, setSuggestions] = useState([])
  const {notify} = useContext(SnackbarContext)
  const [modalUsersList,setModalUsersList] = useState([])
  const [modalGroupsList,setModalGroupsList] = useState([])
  const [modalMemberOfList,setModalMemberOfList] = useState([])

  const { data: userData, loading: userLoading, refetch: userRefetch, error: userError } = useGet(
    `/api/groups/${group_id}/users`
  )

  const { data: allUsersData } = useGet('/api/users/')
  const { data: allGroupsData } = useGet('/api/groups/')

  const { data: nestedGroupData, loading: nestedGroupLoading, refetch: nestedGroupRefetch, error: nestedGroupError } = useGet(
    `/api/groups/${group_id}/groups`
  )

  const { data: memberOfGroupData, loading: memberOfGroupLoading, refetch: memberOfGroupRefetch, error: memberOfGroupError } = useGet(
    `/api/groups/${group_id}/memberof`
  )

  const { data: requestData, loading: requestLoading, refetch: requestRefetch, error: requestError } = useGet(
    `/api/groups/${group_id}/requests`
  )

  const { data: adminGroupsData} = useGet(
    `/api/admin/permissions/${user.username}`
  )

  const { data: groupData } = useGet(
    `/api/groups/${group_id}`
  )

  const { data: adminUsers } = useGet(
    `/api/users/${user.username}/users`
  )

  const adminGroups = adminGroupsData && adminGroupsData.data ? adminGroupsData.data : []

  const deleteFromList = (name) => {
    setAddList(addList => ([...(addList.filter(t => t.key !== name))]))
  }

  const addToAddList = (item) => {
    let temp = addList
    if (showModal === USER){
      for(let t = 0; t < temp.length; t++){
        if(temp[t].key === item.username){
          notify({
            body: <center><b>{item.username}</b> is already in the list of users to add</center>
          })
          return
        }
      }
      temp.push(
        <Chip 
          className={styles.chip}
          icon={item.validated === false ? '' : <Avatar className={styles.UserIcon} size="medium" user={item}/>}
          key={item.username} 
          label={<div style={{marginLeft:'5px'}}>{item.username}</div>} 
          trailingIcon="close" 
          onTrailingIconInteraction={() => deleteFromList(item.username)} 
        />
      )
      //remove from suggestions
    }
    else{
      for(let t = 0; t < temp.length; t++){
        if(temp[t].key === item.name){
          notify({
            body: <center><b>{item.name}</b> is already in the list of groups to add</center>
          })
          return
        }
      }
      temp.push(
        <Chip 
          className={styles.chip}
          key={item.name} 
          label={item.name} 
          trailingIcon="close" 
          onTrailingIconInteraction={() => deleteFromList(item.name)} 
        />
      )
    }
    setAddList(addList => ([...temp]))
    handleChange("")
    setValue("")
  }

  const handleSubmit = async () => {
    logEvent({
      category: 'GroupInfo',
      action: `add user to group`
    })
    setPosting(true)
    setAddingError(false)
    if(value.length > 0){
      setPosting(false)
      setAddingError(true)
      return
    }
    let successfulAdds = []
    if (showModal === USER) {
      for(let count = 0; count < addList.length; count++){
        try {
          let tempArray = modalUsersList
          tempArray = tempArray.filter(u => u.username !== addList[count].key)
          tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
          setModalUsersList(tempArray)//removes the user from the list of users to show in the modal
          await axios.post(`/api/groups/${group_id}/users/${addList[count].key}`)
          await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:addList[count].key, member_group_name:null, group_name:group_id, 
            action:'add user', location:'group info'})
          await userRefetch()
          successfulAdds.push(addList[count].key)
        }
        catch (error) {
          if(userData.data[0].users.find(u => u.username === addList[count].key)){
            notify({
              body: <center>Error: <b>{addList[count].key}</b> is already a member of <b>{group_id}</b></center>
            })
          }
          else{
          notify({
            body: <center>Error: failed to add <b>{addList[count].key}</b> as a member of <b>{group_id}</b></center>
            })
          }
          console.error(error)
        }
      }
      setPosting(false)
      if (successfulAdds.length === 1){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b> as a member to <b>{group_id}</b></center>
        })
      }
      else if(successfulAdds.length === 2){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b> as members to <b>{group_id}</b></center>
        })
      }
      else if(successfulAdds.length === 3){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b> as members to <b>{group_id}</b></center>
        })
      }
      else if(successfulAdds.length > 3){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} others</b> as members to <b>{group_id}</b></center>
        })
      }
    } else if (showModal === GROUP) {
      for(let count = 0; count < addList.length; count++){
        try {
          let tempArray = modalGroupsList
          tempArray = tempArray.filter(g => g.name !== addList[count].key)
          tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)//dedup
          setModalGroupsList(tempArray)//removes the group from the list of groups to show in the modal
          await axios.post(`/api/groups/${group_id}/groups/${addList[count].key}`)
          await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:null, member_group_name:addList[count].key, group_name:group_id, 
            action:'add group', location:'group info'})
          await nestedGroupRefetch()
          successfulAdds.push(addList[count].key)
        } catch (error) {
          notify({
            body: <center>Error: failed to add <b>{addList[count].key}</b> as a member of <b>{group_id}</b></center>
          })
          console.error(error)
        }
      }
      setPosting(false)
      if (successfulAdds.length === 1){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b> as a member to <b>{group_id}</b></center>
        })
      }
      else if(successfulAdds.length === 2){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b> as members to <b>{group_id}</b></center>
        })
      }
      else if(successfulAdds.length === 3){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b> as members to <b>{group_id}</b></center>
        })
      }
      else if(successfulAdds.length > 3){
        notify({
          body: <center>Added <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} others</b> as members to <b>{group_id}</b></center>
        })
      }
    } else if (showModal === MEMBEROF) {
      for(let count = 0; count < addList.length; count++){
        try {
            let tempArray = modalMemberOfList
            tempArray = tempArray.filter(m => m.name !== addList[count].key)
            tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)//dedup
            setModalMemberOfList(tempArray)//removes the group from the list of groups to show in the modal
            await axios.post(`/api/groups/${addList[count].key}/groups/${group_id}`)
            await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:null, member_group_name:addList[count].key, group_name:group_id, 
              action:'add group member of', location:'group info'})
            await memberOfGroupRefetch()
            successfulAdds.push(addList[count].key)
        } catch (error) {
          notify({
            body: <center>Error: failed to add <b>{group_id}</b> as a member to <b>{addList[count].key}</b></center>
          })
          console.error(error)
        }
      }
      setPosting(false)
      if (successfulAdds.length === 1){
        notify({
          body: <center>Added <b>{group_id}</b> as a member to <b>{successfulAdds[0]}</b></center>
        })
      }
      else if(successfulAdds.length === 2){
        notify({
          body: <center>Added <b>{group_id}</b> as a member to <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b></center>
        })
      }
      else if(successfulAdds.length === 3){
        notify({
          body: <center>Added <b>{group_id}</b> as a member to <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b></center>
        })
      }
      else if(successfulAdds.length > 3){
        notify({
          body: <center>Added <b>{group_id}</b> as a member to <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} other groups</b></center>
        })
      }
    }
    setPosting(false)
    handleOnClose()
  }

  const handleOnClose = () => {
    setShowModal(null)
    setAddList([])
    setValue("")
    setAddingError(false)
    setSuggestions([])
  }

  const removeSuggestion = (item) =>{
    let temp = suggestions
    temp = temp.filter(s => s.username !== item.username)
    setSuggestions(temp)
  }

  const updateSuggestions = (adminsUsers, adminGroups) => {
    let suggestionArray = []
    //adminsUsers is a list of all the users in this session user's admin groups
    //users is a list of all users in this group
    if(adminsUsers && adminsUsers.length > 0){
      for(let s = 0; s < adminsUsers.length; s++){
        if(!users.find(u => u.username === adminsUsers[s].username)){
          suggestionArray.push(adminsUsers[s])
        }
      }
      if(suggestionArray.length > 0){
        suggestionArray = suggestionArray.filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
        if(suggestions.length === 0 && adminGroups.length > 0) {
          setSuggestions(suggestions => ([...suggestionArray]))
        }
      }
    }
  }

  const handleChange = (val) => {
    setValue(val)
  }

  const getValue = () => {
    return value
  }

  const getList = () => {
    if (showModal === USER) return modalUsersList
    if (showModal === GROUP) return modalGroupsList
    if (showModal === MEMBEROF) return modalMemberOfList
    return ""
  }

  const handleUserDelete = async (ev, userToDelete) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `delete user from group`
    })
    //add the user to modalUsersList so we can see it in the modal to add members
    let tempArray = modalUsersList
    tempArray.push(userToDelete)
    setModalUsersList(tempArray)

    setDelUser(userToDelete.username)
    await axios.delete(`/api/groups/${group_id}/users/${userToDelete.username}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userToDelete.username, member_group_name:null, group_name:group_id, 
      action:'delete user', location:'group info'})
    await userRefetch()
    setDelUser("")
    ev.stopPropagation()
    notify({
      body: <>Removed <b>{userToDelete.firstname} {userToDelete.lastname}</b> from group</>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteUser(e, userToDelete.username)
        }
      ],
    })
  }

  const handleGroupDelete = async (ev, group_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `delete group from group`
    })
    setDelGroup(group_name)

    let obj = {name:group_name}
    let tempArray = modalGroupsList
    tempArray.push(obj)
    setModalGroupsList(modalGroupsList => ([...tempArray]))

    await axios.delete(`/api/groups/${group_id}/groups/${group_name}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:null, member_group_name:group_name, group_name:group_id, 
      action:'delete group', location:'group info'})
    await nestedGroupRefetch()
    setDelGroup("")
    ev.stopPropagation()
    notify({
      body: <>Removed <b>{group_name}</b> from group</>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteGroup(e, group_name)
        }
      ],
    })
  }

  const handleMemberOfDelete = async (ev, memberOf_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `delete group as member of another group`
    })
    setDelMemberOf(memberOf_name)

    let obj = {name:memberOf_name}
    let tempArray = modalMemberOfList
    tempArray.push(obj)
    setModalMemberOfList(modalMemberOfList => ([...tempArray]))

    await axios.delete(`/api/groups/${group_id}/memberOf/${memberOf_name}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:null, member_group_name:memberOf_name, group_name:group_id, 
      action:'delete group member of', location:'group info'})
    await memberOfGroupRefetch()
    setDelMemberOf("")
    ev.stopPropagation()
    notify({
      body: <>Removed this group as a member of the <b>{memberOf_name}</b> group</>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteMemberOf(e, memberOf_name)
        }
      ],
    })
  }

  const handleRequestDelete = async (ev, usernameDelete, userToDelete) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `deny group access request`
    })
    setDelRequest(usernameDelete)
    await axios.delete(`/api/groups/${group_id}/requests/${usernameDelete}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:usernameDelete, member_group_name:null, group_name:group_id, 
      action:'deny request', location:'group info'})
    await requestRefetch()
    setDelRequest("")
    ev.stopPropagation()
    notify({
      body: <center>Denied <b>{userToDelete.firstname} {userToDelete.lastname}</b> access to this group</center>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteRequest(e, usernameDelete)
        }
      ],
    })
  }

  const handleRequestApprove = async (ev, username, addedUser) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `approve group access request`
    })
    setAddRequest(username)
    try{
    await axios.post(`/api/groups/${group_id}/users/${username}`)
    await axios.put(`/api/groups/${group_id}/requests/${username}`)
    await axios.delete(`/api/groups/${group_id}/requests/${username}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:group_id, 
      action:'approve request', location:'group info'})
    await requestRefetch()
    setAddRequest("")
    ev.stopPropagation()
    notify({
      body: <>Added <b>{addedUser.firstname} {addedUser.lastname}</b> as a member to <b>{group_id}</b></>,
      dismissesOnAction: true,
    })
    await userRefetch()
    }
    catch (error){
        setAddRequest("")
          ev.stopPropagation()
            const {data} = await axios.get(`/api/groups/${group_id}/users`)
            if(data.data[0].users.find(u => u.username === username)){
              notify({
                body: <>Error: <b>{addedUser.firstname} {addedUser.lastname}</b> is already a member of <b>{group_id}</b></>,
                dismissesOnAction: true,
              })
            }
            else{
              notify({
                body: <>Error: Internal Server Error</>,
                dismissesOnAction: true,
              })
            }
      }
  }

  const handleUndeleteUser = async (ev, usernameUndelete) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `undelete user from group`
    })
    const undoUsername = usernameUndelete
    const undoGroup = group_id
    const url = `/api/groups/${undoGroup}/users/${undoUsername}`
    await axios.post(url)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:undoUsername, member_group_name:null, group_name:undoGroup, 
      action:'undelete user', location:'group info'})
    await userRefetch()
    ev.stopPropagation()
  }

  const handleUndeleteGroup = async (ev, group_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `undelete group from group`
    })
    const undoGroup = group_id
    const undoMemberGroup = group_name
    const url = `/api/groups/${undoGroup}/groups/${undoMemberGroup}`
    await axios.post(url)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:null, member_group_name:undoMemberGroup, group_name:undoGroup, 
      action:'undelete group', location:'group info'})
    await nestedGroupRefetch()
    ev.stopPropagation()
  }

  const handleUndeleteMemberOf = async (ev, parent_group_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `undelete group as member of another group`
    })
    const undoGroup = group_id
    const url = `/api/groups/${parent_group_name}/groups/${undoGroup}`
    await axios.post(url)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:null, member_group_name:parent_group_name, group_name:undoGroup, 
      action:'undelete group member of', location:'group info'})
    await memberOfGroupRefetch()
    ev.stopPropagation()
  }

  const handleUndeleteRequest = async (ev, username) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'GroupInfo',
      action: `undeny group access request`
    })
    const undoGroup = group_id
    const requestUser = username
    await axios.post(`/api/groups/${undoGroup}/requests`, {username: requestUser})
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:requestUser, member_group_name:null, group_name:undoGroup, 
      action:'undeny request', location:'group info'})
    await requestRefetch()
    ev.stopPropagation()
  }


  const users = userData && userData.data && userData.data[0] && userData.data[0].users ? userData.data[0].users : []
  //users => all the users in this group
  const groups = nestedGroupData && nestedGroupData.data && nestedGroupData.data[0] && nestedGroupData.data[0].groups ? nestedGroupData.data[0].groups : []
  const requests = requestData && requestData.data ? requestData.data : []
  const thisGroup = groupData && groupData.data ? groupData.data : []
  const memberOf = memberOfGroupData && memberOfGroupData.data && memberOfGroupData.data[0] && memberOfGroupData.data[0].groups ? memberOfGroupData.data[0].groups : []

  let allUsers = allUsersData && allUsersData.data ? allUsersData.data : []
  let allGroups = allGroupsData && allGroupsData.data ? allGroupsData.data : []
  let allGroupsOf = [].concat(allGroups)
  if(allUsers.length > 0){
    for(let u = 0; u < users.length; u++){
      allUsers = allUsers.filter(au => (au ? (au.username !== users[u].username) : false))
    }
    if(modalUsersList.length === 0) setModalUsersList(allUsers)
  }//allUsers now has the list of all users except those already in this group 
  if(allGroups.length > 0){
    for(let g = 0; g < groups.length; g++){
      allGroups = allGroups.filter(ag => (ag ? (ag.name !== groups[g].name) : false))
    }
    if(modalGroupsList.length === 0) setModalGroupsList(allGroups)
  }//allGroups now has the list of all groups except those already in this group 
  if(allGroupsOf.length > 0){
    for(let m = 0; m < memberOf.length; m++){
      allGroupsOf = allGroupsOf.filter(ag => (ag ? (ag.name !== memberOf[m].name) : false))
    }
    if(modalMemberOfList.length === 0) setModalMemberOfList(allGroupsOf)
  }//allGroupsOf now has the list of all groups except those this group is already a member of

  for(let i = 0; i < memberOf.length; i++){
    for(let a = 0; a < adminGroups.length; a++){
      if(memberOf[i].name === adminGroups[a].group_name){
        memberOf[i].isAdmin = true
      }
    }
  }
  for(let i = 0; i < groups.length; i++){
    for(let a = 0; a < adminGroups.length; a++){
      if(groups[i].name === adminGroups[a].group_name){
        groups[i].isAdmin = true
      }
    }
  }
  if(!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')){
    if (!adminGroups.find((a) => a.group_name === group_id)) {
      return <NoAccess />
    }
  }
  const groupTitle = parseName(group_id)

  const navigateAdminInfo = (group_id) => {
    logEvent({
      category: 'GroupInfo',
      action: `navigate to admininfo page`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:parseName(group_id)})
    navigate(`/superadmin/${group_id}`,{ state: {backNav:backNavArray, search:location.state.search }})
  }

  const viewTab = (index) => {
    logEvent({
      category: 'GroupInfo',
      action: (index === 0 ? 'view members tab' : index === 1 ? 'view memberof tab' : 'view request tab')
    })
    setActiveTab(index)
  }

  const backButton = () => {
    let tempNav = location.state.backNav[location.state.backNav.length-1].path
    let tempArr = location.state.backNav
    tempArr.pop()
    navigate(tempNav,{ state: {backNav:tempArr, search:location.state.search, isBack:true }})
  }

  return (
    <div className={styles.container}>
      <LogoutUser/>
      {location.state.backNav.length > 0 &&
        <Button  icon="arrow_back" onClick={() => backButton()}>
          Back to {location.state.backNav[location.state.backNav.length-1].pageName}
        </Button>
      }
      <div className={styles.header}>
        <h2 className={styles.title}>{groupTitle + " Group"}</h2>
        {user && user.roles && user.roles.find((r) => r.role === "super-admin") && (
          <Button outlined icon="build" onClick={() => navigateAdminInfo(group_id)}>
            Admin
          </Button>
        )}
      </div>
      <p className={styles.subTitle}>ID: {group_id}</p>
      {thisGroup.description? <p className={styles.subTitle}>{thisGroup.description.replace(/^{"/,"").replace(/"}$/,"")}</p> : <></>}
      <TabBar className={styles.tab} activeTabIndex={activeTab} onActivate={(e) => viewTab(e.detail.index)}>
        <Tooltip content="See all members of this group" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab >
            Members {!userLoading && !userError && !nestedGroupLoading && !nestedGroupError && <div className={styles.tabNumber}>{users.length + groups.length}</div>}
          </Tab>
        </Tooltip>
        <Tooltip content="See groups this group is a member of" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab >
            Member Of {!memberOfGroupLoading && !memberOfGroupError && <div className={styles.tabNumber}>{memberOf.length}</div>}
          </Tab>
        </Tooltip>
        <Tooltip content="Approve or deny group access requests" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab>
            Requests {!requestLoading && !requestError && <div className={styles.tabNumber}>{requests.length}</div>}
          </Tab>
        </Tooltip>
      </TabBar>
      {activeTab === 0 ? (
        <MemberTab
          loading={userLoading}
          loading2={nestedGroupLoading}
          users={users}
          groups={groups}
          error={userError}
          error2={nestedGroupError}
          setShowModal={setShowModal}
          setSuggestions={setSuggestions}
          updateSuggestions={updateSuggestions}
          handleUserDelete={handleUserDelete}
          delUser={delUser}
          handleGroupDelete={handleGroupDelete}
          delGroup={delGroup}
          navigate={navigate}
          desc={thisGroup.description}
          groupTitle={groupTitle}
          adminUsersLoading={(adminUsers? adminUsers.data.length : [])}
          adminUsers={adminUsers? adminUsers.data : []}
          adminGroups={adminGroups}
          location={location}
          group_name={group_id}
        />
      ) : activeTab === 1 ? (
        <MemberOfTab
          loading={memberOfGroupLoading}
          memberOf={memberOf}
          error={memberOfGroupError}
          setShowModal={setShowModal}
          handleMemberOfDelete={handleMemberOfDelete}
          delMemberOf={delMemberOf}
          navigate={navigate}
          desc={thisGroup.description}
          groupTitle={groupTitle}
          location={location}
          group_name={group_id}
        />
      ):(
        <RequestTab
          loading={requestLoading}
          requests={requests}
          error={requestError}
          handleRequestDelete={handleRequestDelete}
          handleRequestApprove={handleRequestApprove}
          delRequest={delRequest}
          addRequest={addRequest}
          desc={thisGroup.description}
        />
      )
    }
      <NewMemberDialog
        open={showModal !== null}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        onChange={handleChange}
        value={getValue()}
        list={getList()}
        type={showModal}
        posting={posting}
        error={addingError}
        addList={addList}
        addToAddList={addToAddList}
        suggestions={suggestions}
        removeSuggestion={removeSuggestion}
      />
    </div>
  )
}

const MemberOfTab = ({loading, memberOf, error, setShowModal, handleMemberOfDelete, delMemberOf, navigate, desc, groupTitle, group_name, location}) => {
  const { user } = useContext(AuthContext)
  const nothing = () => {}
  const navigateGroupInfoMemberOfTab = (m) => {
    logEvent({
      category: 'GroupInfo',
      action: `navigate to another groupinfo page from memberof tab`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:parseName(group_name)})
    navigate("/admin/groups/" + (m["name"] || m["uuid"]),{ state: {backNav:backNavArray, search:location.state.search }})
  }

  return (
    <div>
      <br/>
      <h3 className={styles.listLabel}>&nbsp;Member Of</h3>
      {user && user.roles && user.roles.find((r) => r.role === "super-admin") && (
        <Button raised onClick={() => setShowModal(MEMBEROF)} disabled={error} className={styles.right}>
          Add Group
        </Button>
      )}
      <p>&nbsp;The following is a list of all groups that the {groupTitle} group is a part of. </p>
      <br/>
      {!loading?
        <div className={(desc? styles.groupsContainerWithDescription : styles.groupsContainer)} style={{height: memberOf.length > 0 ? memberOf.length * 80 : 80}}>
          <VirtualList
            list={memberOf || []}
            sortBy="name"
            primaryText={(m) => m.name}
            secondaryText={(m) => m.name}
            onItemClick={(m) => 
              (m.isAdmin || (user && user.roles && user.roles.find((r) => r.role === "super-admin"))) 
              ? navigateGroupInfoMemberOfTab(m)  
              : nothing()
            }
            icon={(user && user.roles && user.roles.find((r) => r.role === "super-admin")) ? "clear":
              (m) => m.isAdmin ? "keyboard_arrow_right" : ""
            }
            tooltipText1={(user && user.roles && user.roles.find((r) => r.role === "super-admin")) ? "Remove this group" : "See the information for this group"}
            onIconClick={async (e, m) => await handleMemberOfDelete(e, m.name)}
            itemKey={delMemberOf}
            itemCompare="name"
            emptyListMessage='This group is not a member of any groups.'
          />
        </div>
      : (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )
      }
    </div>
  )
}

const MemberTab = ({ loading, loading2, adminUsersLoading, users, groups, error, error2, setShowModal, handleUserDelete, delUser, handleGroupDelete, delGroup, navigate, desc, groupTitle, setSuggestions, updateSuggestions, adminUsers, adminGroups, location, group_name }) => {
  const { user } = useContext(AuthContext)
  const nothing = () => {}
  const navigateGroupInfoMembersTab = (g) => {
    logEvent({
      category: 'GroupInfo',
      action: `navigate to another groupinfo page from members tab`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:parseName(group_name)})
    navigate("/admin/groups/" + (g["name"] || g["uuid"]),{ state: {backNav:backNavArray, search:location.state.search }})
  }
  const navigateUserInfoMembersTab = (u) => {
    logEvent({
      category: 'GroupInfo',
      action: `navigate to userinfo page from members tab`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:parseName(group_name)})
    
    navigate('/admin/users/' + u.username,{ state: {backNav:backNavArray, search:location.state.search }})
  }

  const addUserButton = () => {
    setSuggestions([])
    updateSuggestions(adminUsers, adminGroups)
    setShowModal(USER)
  }
  return (
    <List>
      <CollapsibleList 
        startOpen
        handle={
          <div>
            <Tooltip content="Click to open or collapse the users list" align="bottom" showArrow enterDelay={200}>
              <SimpleListItem
                text={<h3>&nbsp;Users <div className={styles.tabNumber}>{users.length}</div></h3>}
                metaIcon="expand_more"
              />
            </Tooltip>
          </div>
        }
      >
        <div className={styles.collapsible}>
          <div className={styles.buttonContainer}>
            {/* <h3 className={styles.listLabel}>Users {!loading && !error && <div className={styles.tabNumber}>{users.length}</div>}</h3> */}
            <p>The following is a list of all users who are members of the {groupTitle} group.</p>
            {user && user.roles && (user.roles.find((r) => r.role === "super-admin") || user.roles.find((r) => r.role === "admin")) &&
              <Button raised onClick={() => addUserButton()} disabled={error}>
                Add User
              </Button>
            }
          </div>
          {!loading ? (
            !error ? (
              <div className={(desc? 
                styles.groupsContainerWithDescription : styles.groupsContainer) } 
                style={{height: users.length > 0 ? users.length > 4 ? 360 : users.length * 80 : 80}}
              >
                <VirtualList
                  list={users || []}
                  primaryText={(u) => u.firstname + " " + u.lastname}
                  secondaryText={(u) => u.username}
                  icon="clear"
                  tooltipText1="Remove this user from the group"
                  onItemClick = {(u) => navigateUserInfoMembersTab(u)}
                  onIconClick={async (e, u) => await handleUserDelete(e, u)}
                  itemKey={delUser}
                  itemCompare="username"
                  emptyListMessage='There are currently no users in this group.'
                />
              </div>
            ) : (
              error.response.status === 403 && (
                <div className={styles.errorContainer}>
                  <p className={styles.forbidden}>
                    <Icon icon={{ icon: "block", size: "medium" }} className={styles.forbiddenIcon} />
                    Not authorized to make changes to users
                  </p>
                </div>
              )
            )
          ) : (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            )
          }
        </div>
      </CollapsibleList>
      <CollapsibleList
        startOpen
        handle={
          <div>
            <Tooltip content="Click to open or collapse the groups list" align="bottom" showArrow enterDelay={700}>
              <SimpleListItem
                text={<h3>Groups <div className={styles.tabNumber}>{groups.length}</div></h3>}
                metaIcon="expand_more"
              />
            </Tooltip>
          </div>
        }
      >
        <div className={styles.collapsible}>
          <div className={styles.buttonContainer}>
            {/* <h3 className={styles.listLabel}>Groups {!loading2 && !error2 && <div className={styles.tabNumber}>{groups.length}</div>}</h3> */}
            <p>The following is a list of all groups that are members of the {groupTitle} group.</p>
              {user && user.roles && user.roles.find((r) => r.role === "super-admin") && (
                <Button raised onClick={() => setShowModal(GROUP)} disabled={error}>
                  Add Group
                </Button>
              )}
          </div>
          {!loading2 ? (
            !error2 ? (
              <div className={(
                desc? styles.groupsContainerWithDescription : styles.groupsContainer) } 
                style={{height: groups.length > 0 ? groups.length > 4 ? 360 : groups.length * 80 : 80}}
              >
                <VirtualList
                  list={groups || []}
                  sortBy="name"
                  primaryText={(g) => g.name}
                  secondaryText={(g) => g.name}
                  onItemClick={(g) => 
                    (g.isAdmin || (user && user.roles && user.roles.find((r) => r.role === "super-admin"))) 
                    ? navigateGroupInfoMembersTab(g)  
                    : nothing()
                  }
                  icon={(user && user.roles && user.roles.find((r) => r.role === "super-admin")) ? "clear":
                    (m) => m.isAdmin ? "keyboard_arrow_right" : ""
                  }
                  tooltipText1={(user && user.roles && user.roles.find((r) => r.role === "super-admin")) ? "Remove this group" : "See the information for this group"}
                  onIconClick={(user && user.roles && user.roles.find((r) => r.role === "super-admin")) ? (async (e, g) => await handleGroupDelete(e, g.name)) : nothing()}
                  itemKey={delGroup}
                  itemCompare="name"
                  emptyListMessage='This group currently has no groups as members.'
                />
              </div>
            ) : (
              error2.response.status === 403 && (
                <div className={styles.errorContainer}>
                  <p className={styles.forbidden}>
                    <Icon icon={{ icon: "block", size: "medium" }} className={styles.forbiddenIcon} />
                    Not authorized to make changes to groups
                  </p>
                </div>
              )
            )
          ) : (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            )
          }
        </div>
      </CollapsibleList>
    </List>
  )
}

const RequestTab = ({ loading, requests, error, handleRequestDelete, handleRequestApprove, delRequest, addRequest, desc }) => {
  return (
    <div>
      <div className={styles.listHeader}>
        <h3> &nbsp;Access Requests</h3>
      </div>
      {!loading ? (
        !error ? (
          <div className={(desc? styles.groupsContainerWithDescription : styles.groupsContainer) } style={{height: requests.length > 0 ? requests.length * 80 : 80}}>
            <VirtualList
              list={requests || []}
              primaryText={(r) => r.user.firstname + " " + r.user.lastname}
              secondaryText={(r) => r.user.username}
              tooltipText1="Delete this user's request"
              icon="clear"
              tooltipText2="Grant this user membership in the group"
              secondaryIcon="check"
              onIconClick={async (e, r) => await handleRequestDelete(e, r.user.username, r.user)}
              onSecondaryIconClick={async (e, r) => await handleRequestApprove(e, r.user.username, r.user)}
              itemKey={delRequest}
              secondaryItemKey={addRequest}
              itemCompare="user"
              emptyListMessage='There are currently no requests in this group.'
              disableHover
            />
          </div>
        ) : (
          error.response.status === 403 && (
            <div className={styles.errorContainer}>
              <p className={styles.forbidden}>
                <Icon icon={{ icon: "block", size: "medium" }} className={styles.forbiddenIcon} />
                Not authorized to make changes to groups
              </p>
            </div>
          )
        )
      ) : (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
