import React, {createContext, useReducer, useEffect} from 'react'
import AppReducer from './AuthReducer'
import {auth} from './AuthActions'

const initialState = {
  user: null,
  loaded: false,
  expiration: null,
  lastAuth: null
}

// Create Context
export const AuthContext = createContext(initialState)

// Provider Component
export const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  useEffect(() => {
    dispatch({type: 'INIT_LOGIN', action: null})
    auth(dispatch)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loaded: state.loaded,
        lastAuth: state.lastAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
