import React from 'react'

import {Avatar as RMWCAvatar} from '@rmwc/avatar'
import '@rmwc/avatar/avatar.css'


export default function Avatar({user, size, className, ...props}){
  const url = null

  return (
    <RMWCAvatar
      className={className}
      size={size}
      src={url}
      name={user && (user.firstname + ' ' + user.lastname)}
      {...props}
    />
  )
}
