import React, {useState} from 'react'


export default function LogoutUser() {
  const [passed, setPassed] = useState(false)

  setInterval(function(){ 
    if(passed){
      window.location.href = "/logout"
    }
    setPassed(true)
  }, 1000 * 60 * 60)

  return (
    <div></div>
  )
}
