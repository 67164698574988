import React, {useContext} from 'react'
import styles from './Results.module.css'
import {useGet} from '../../utils/_hooks'

import VirtualList from '../../components/VirtualList/VirtualList'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import parseName from '../../utils/parseName'
import {AuthContext} from '../../context/AuthContext'
import NoAccess from '../NoAccess/NoAccess'

import { List, CollapsibleList, SimpleListItem } from "@rmwc/list"
import '@material/list/dist/mdc.list.css'
import '@rmwc/list/collapsible-list.css'
import { Tooltip } from "@rmwc/tooltip"
import "@rmwc/tooltip/tooltip.css"
import {CircularProgress} from '@rmwc/circular-progress'
import '@rmwc/circular-progress/styles'
import { Icon } from "@rmwc/icon"
import "@rmwc/icon/icon.css"
import { Button } from "@rmwc/button"
import "@material/button/dist/mdc.button.css"

export default function Results({globalSearch, location, navigate}) {
  const {user} = useContext(AuthContext)

  const { data: adminGroupsData, loading:adminGroupsLoading, error:adminGroupsError} = useGet(`/api/admin/permissions/${user.username}`)

  const { data: allGroupsData, loading:allGroupsLoading, error:allGroupsError } = useGet('/api/groups/')
  const { data: allUsersData, loading:allUsersLoading, error:allUsersError } = useGet('/api/users/')

  let adminGroups = adminGroupsData && adminGroupsData.data ? adminGroupsData.data : []//adminGroups is a list of the session user's admin groups
  let allGroups = allGroupsData && allGroupsData.data ? allGroupsData.data : []//allGroups is a list of all groups and their information
  let allUsers = allUsersData && allUsersData.data ? allUsersData.data : []//allUsers is a list of all users and their information
  
  if (!user || !user.roles || (!user.roles.find((r) => r.role === 'super-admin') && !user.roles.find((r) => r.role === 'admin'))) {
    return <NoAccess />
  }

  const returnAdminGroups = (adminGroups || [])
  .map((g) => {
    return {
      group_guid: g.guid,
      group_name: parseName(g.group_name),
      name: parseName(g.group_name),
      group_id: g.group_name,
      group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
    }
  })
  .filter(
    (g) =>
      g.group_name.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      g.group_id.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )
  .filter((v,i,a)=>a.findIndex(t=>(t.group_name === v.group_name))===i)//dedup
  .sort((a, b) => {
    let _a = (a.group_id || '').toLowerCase()
    let _b = (b.group_id || '').toLowerCase()
    return _a > _b ? 1 : -1
  })
  .sort((a, b) => {
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return 1;
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return -1;
    return 0
  })

  const returnAllGroups = (allGroups || [])
  .map((g) => {
    return {
      group_guid: g.guid,
      group_name: parseName(g.name),
      name: parseName(g.name),
      group_id: g.name,
      group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
    }
  })
  .filter(
    (g) =>
      g.group_name.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      g.group_id.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )
  .filter((v,i,a)=>a.findIndex(t=>(t.group_name === v.group_name))===i)//dedup
  .sort((a, b) => {
    let _a = (a.group_id || '').toLowerCase()
    let _b = (b.group_id || '').toLowerCase()
    return _a > _b ? 1 : -1
  })
  .sort((a, b) => {
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return 1;
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return -1;
    return 0
  })

  const returnAllUsers = (allUsers || [])
  .map((u) => {
    return {
      email: u.email,
      firstname: u.firstname,
      lastname: u.lastname,
      name: u.firstname + ' ' + u.lastname,
      guid: u.guid,
      last_session_datetime: u.last_session_datetime,
      username: u.username,
      uuid: u.uuid
    }
  })
  .filter(
    (u) =>
      u.name.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      u.username.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )
  .filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
  .sort((a, b) => {
    let _a = (a.username || '').toLowerCase()
    let _b = (b.username || '').toLowerCase()
    return _a > _b ? 1 : -1
  })
  .sort((a, b) => {
    if ((a.firstname && b.firstname && a.firstname.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.firstname.toUpperCase().indexOf(globalSearch.toUpperCase()) )
    || (a.lastname && b.lastname && a.lastname.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.lastname.toUpperCase().indexOf(globalSearch.toUpperCase()) )
    || a.username.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.username.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return 1;
      if ((a.firstname && b.firstname && a.firstname.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.firstname.toUpperCase().indexOf(globalSearch.toUpperCase()) )
      || (a.lastname && b.lastname && a.lastname.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.lastname.toUpperCase().indexOf(globalSearch.toUpperCase()) )
      || a.username.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.username.toUpperCase().indexOf(globalSearch.toUpperCase()))
        return -1;
    return 0
  })

  let showGroups
  let showGroupsLoading
  let showGroupsError

  if(user.roles.find((r) => r.role === 'super-admin')){
    //set group stuff to allgroups
    showGroups = returnAllGroups
    showGroupsLoading = allGroupsLoading
    showGroupsError = allGroupsError
  }
  else{
    //set group stuff to admingroups
    showGroups = returnAdminGroups
    showGroupsLoading = adminGroupsLoading
    showGroupsError = adminGroupsError
  }

  const navigatePage = (input, type) => {
    let tempObj = {path:location.pathname, pageName:"Results"}
    let backNavArray = location.state.backNav

    backNavArray.push(tempObj)

    if(type === 'user') navigate(`/admin/users/${input}`,{ state: {backNav:backNavArray, search:location.state.search, isFromGlobal:true}})
    else if(type === 'group') navigate(`/admin/groups/${input}`,{ state: {backNav:backNavArray, search:location.state.search, isFromGlobal:true}})
  }

  const backButton = () => {
    let tempNav = location.state.backNav[location.state.backNav.length-1].path
    let tempArr = location.state.backNav
    tempArr.pop()
    navigate(tempNav,{ state: {backNav:tempArr, search:location.state.search, isBack:true }})
  }

  return (
    <div className={styles.container}>
      <Button  icon="arrow_back" onClick={() => backButton()}>
        Back to {location.state.backNav[location.state.backNav.length-1].pageName}
      </Button>
      <div className={styles.header}>
        <h2 className={styles.title}>Results</h2>
      </div>
      <p className={styles.subTitle}>&nbsp;&nbsp;&nbsp;&nbsp;Based on your search for "{globalSearch}"</p>
      <LogoutUser/>
      <List>
        <CollapsibleList 
          startOpen
          handle={
            <div>
              <Tooltip content="Click to open or collapse the users list" align="bottom" showArrow enterDelay={200}>
                <SimpleListItem
                  text={<h3>&nbsp;Users</h3>}
                  metaIcon="expand_more"
                />
              </Tooltip>
            </div>
          }
        >
          <div className={styles.collapsible}>
            <div className={styles.buttonContainer}>
              <p>The following is a list of users returned by your search</p>
            </div>
            {!allUsersLoading ? (
              !allUsersError ? (
                <div className={styles.groupsContainer} 
                  style={{height: returnAllUsers.length > 0 ? returnAllUsers.length > 4 ? 360 : returnAllUsers.length * 80 : 80}}
                >
                  <VirtualList
                    list={returnAllUsers || []}
                    primaryText={(u) => u.firstname + " " + u.lastname}
                    secondaryText={(u) => u.username}
                    icon="keyboard_arrow_right"
                    tooltipText1="See this user's information"
                    onItemClick = {(u) => navigatePage(u.username, 'user')}
                    itemKey=''
                    itemCompare="username"
                    emptyListMessage='There are no users to show'
                  />
                </div>
              ) : (
                allUsersError.response.status === 403 && (
                  <div className={styles.errorContainer}>
                    <p className={styles.forbidden}>
                      <Icon icon={{ icon: "block", size: "medium" }} className={styles.forbiddenIcon} />
                      Not authorized to make changes to users
                    </p>
                  </div>
                )
              )
            ) : (
                <div className={styles.loading}>
                  <CircularProgress />
                </div>
              )
            }
          </div>
        </CollapsibleList>
        <CollapsibleList
          startOpen
          handle={
            <div>
              <Tooltip content="Click to open or collapse the groups list" align="bottom" showArrow enterDelay={700}>
                <SimpleListItem
                  text={<h3>Groups</h3>}
                  metaIcon="expand_more"
                />
              </Tooltip>
            </div>
          }
        >
          <div className={styles.collapsible}>
            <div className={styles.buttonContainer}>
              <p>The following is a list of groups returned by your search </p>
            </div>
            {!showGroupsLoading ? (
              !showGroupsError ? (
                <div className={styles.groupsContainer} 
                  style={{height: showGroups.length > 0 ? showGroups.length > 4 ? 360 : showGroups.length * 80 : 80}}
                >
                  <VirtualList
                    list={showGroups || []}
                    sortBy="name"
                    primaryText={(g) => g.group_name}
                    secondaryText={(g) => g.group_id}
                    onItemClick={(g) => navigatePage(g.group_id, 'group') }
                    icon={"keyboard_arrow_right"}
                    tooltipText1={"See the information for this group"}
                    itemKey=''
                    itemCompare="name"
                    emptyListMessage='There are no groups to show'
                  />
                </div>
              ) : (
                showGroupsError.response.status === 403 && (
                  <div className={styles.errorContainer}>
                    <p className={styles.forbidden}>
                      <Icon icon={{ icon: "block", size: "medium" }} className={styles.forbiddenIcon} />
                      Not authorized to make changes to groups
                    </p>
                  </div>
                )
              )
            ) : (
                <div className={styles.loading}>
                  <CircularProgress />
                </div>
              )
            }
          </div>
        </CollapsibleList>
      </List>
    </div>
  )
}

