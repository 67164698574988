import React, {useState, useContext} from 'react'
import axios from 'axios'
import styles from './Profile.module.css'
import {useGet, logEvent} from '../../utils/_hooks'
import {CircularProgress} from '@rmwc/circular-progress'
import {TextField} from '@rmwc/textfield'
import VirtualList from '../../components/VirtualList/VirtualList'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import parseName from '../../utils/parseName'
import {AuthContext} from '../../context/AuthContext'
import NewMemberDialog from "../../components/NewMemberDialog/NewMemberDialog"
import { TabBar, Tab } from "@rmwc/tabs"
import "@rmwc/tabs/styles"
import { Tooltip } from "@rmwc/tooltip"
import '@rmwc/tooltip/tooltip.css'
import '@rmwc/circular-progress/styles'
import '@rmwc/textfield/styles'
import '@rmwc/dialog/styles'
import {SnackbarContext} from '../../context/SnackbarContext'
import { Chip } from '@rmwc/chip'
import '@rmwc/chip/styles';
import { Button } from "@rmwc/button"
import "@material/button/dist/mdc.button.css"

const MEMBEROF = "MEMBEROF"

export default function Profile({navigate, location}) {
  const [search, setSearch] = useState('')
  const [searchSet, setSearchSet] = useState(false)
  const [delUser, setDelUser] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const {user} = useContext(AuthContext)
  const [showModal, setShowModal] = useState(null)
  const [addingError, setAddingError] = useState(false)
  const [posting, setPosting] = useState(false)
  const [memberOfName, setMemberOfName] = useState("")
  const [modalGroupsList,setModalGroupsList] = useState([])
  const [addList, setAddList] = useState([])
  const [addListAllowed, setAddListAllowed] = useState([])

  if(!searchSet && location.state.search && location.state.isBack){
    setSearch(location.state.search)
    setSearchSet(true)
  }

  const {data:requestGroups, error:requestGroupsError, loading:requestGroupsLoading} = useGet(`/api/users/${user.username}/requests/groups`)
  //groups the user can request access to
  const {data: myGroups, error: myGroupsError, loading: myGroupsLoading, refetch: myGroupsRefetch} = useGet(`/api/users/${user.username}/groups`)
  //groups the user is a part of
  const {data: requests, /*error: requestError, loading: requestLoading,*/ refetch: requestsRefetch} = useGet(`/api/users/${user.username}/requests`)
  const {notify} = useContext(SnackbarContext)
  const [sendRequest,setSendRequest] = useState('')

  const { data: adminGroupsData} = useGet(`/api/admin/permissions/${user.username}`)
  const adminGroups = adminGroupsData && adminGroupsData.data ? adminGroupsData.data : []
  //adminGroups is the list of the groups the session user is an admin over

  const { data: allGroupsData } = useGet('/api/groups/')
  let allGroups = allGroupsData && allGroupsData.data ? allGroupsData.data : []
  //allGroups is a list of all groups in the database 

  if(user && user.roles && user.roles.find((r) => r.role === 'super-admin') ){
    if(addListAllowed.length === 0 && allGroups.length > 0){//added this nested if instead of including it above because allGroups was taking too long to load and it was running the else if below
    setAddListAllowed(allGroups)
    }
  }
  else if(user && user.roles && user.roles.find((r) => r.role === 'admin') && adminGroups.length > 0 && addListAllowed.length === 0){
    setAddListAllowed(adminGroups)
  }

  let tempGroups = []
  if(!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')){
    tempGroups = adminGroups
  }
  else{
    tempGroups = allGroups
  }

  if(myGroups && myGroups.data && myGroups.data[0] && myGroups.data[0].groups){
    for(let g = 0; g < myGroups.data[0].groups.length; g++){
      tempGroups = tempGroups.filter(ag => ag.name !== myGroups.data[0].groups[g].name)
    }
  }
  if(modalGroupsList.length === 0 && tempGroups.length > 0) setModalGroupsList(tempGroups)
  //modalGroupsList is now the list of all groups except groups this user is part of

  let requestGroupsList = ((requestGroups && requestGroups.data.groups) || [])
    .map((g) => {
      return {
        group_guid: g.guid,
        group_name: parseName(g.name),
        group_id: g.name,
        group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
      }
    })
    .filter(
      (g) =>
        g.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
        g.group_id.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
    )
    .sort((a, b) => {
      let _a = (a.group_id || '').toLowerCase()
      let _b = (b.group_id || '').toLowerCase()
      return _a > _b ? 1 : -1
    })
  const groups = ((myGroups && myGroups.data && myGroups.data[0] && myGroups.data[0].groups? myGroups.data[0].groups : []) || [])
    .map((g) => {
      return {
        group_guid: g.guid,
        group_name: parseName(g.name),
        group_id: g.name,
        group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
      }
    })
    .filter(
      (g) =>
        g.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
        g.group_id.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
    )
    .sort((a, b) => {
      let _a = (a.group_id || '').toLowerCase()
      let _b = (b.group_id || '').toLowerCase()
      return _a > _b ? 1 : -1
    })

  //go through and remove all the groups in groups from requestGroupsList
  for(let g = 0; g < groups.length; g++){
    requestGroupsList = requestGroupsList.filter(r => r.group_name !== groups[g].group_name)
  }

  for(var i = 0; i < groups.length; i++){
    for(var a = 0; a < adminGroups.length; a++){
      if(groups[i].group_id === adminGroups[a].group_name){
        groups[i].isAdmin = true
      }
    }
  }

  const handleOnClose = () => {
    setShowModal(null)
    setMemberOfName("")
    setAddingError(false)
    setAddList([])
  }

  const handleSubmit = async () => {
    setPosting(true)
    setAddingError(false)
    logEvent({category: 'Profile',action: `adding user to group`})
    let successfulAdds = []
    for(let count = 0; count < addList.length; count++){
      try {
          await axios.post(`/api/groups/${addList[count].key}/users/${user.username}`)
          await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:user.username, member_group_name:null, group_name:addList[count].key, 
            action:'add user', location:'profile'})
          await myGroupsRefetch()
          successfulAdds.push(addList[count].key)
          //remove group from modalGroupsList
          let tempGroupArr = modalGroupsList
          tempGroupArr = tempGroupArr.filter(g => g.name !== addList[count].key)
          setModalGroupsList(modalGroupsList => ([...tempGroupArr]))
      } catch (error) {
        try{
          let groupData = await axios.get(`/api/groups/${addList[count].key}/users`)
          if(groupData.data.data[0].users.find(u => u.username === user.username)){
            notify({
              body: <center>Error: <b>You</b> are already a member of <b>{addList[count].key}</b></center>
            })
            console.error(error)
          }
          else{
            throw new Error('Group Does Not Exist')
          }
        }
        catch(error){
          notify({
            body: <center>Error: failed to add <b>You</b> as a member of <b>{addList[count].key}</b></center>
          })
          console.error(error)
        }
      }
    }
    setPosting(false)
    if (successfulAdds.length === 1){
      notify({
        body: <center>Added <b>{user.firstname} {user.lastname}</b> as a member of <b>{successfulAdds[0]}</b></center>
      })
    }
    else if(successfulAdds.length === 2){
      notify({
        body: <center>Added <b>{user.firstname} {user.lastname}</b> as a member of <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b></center>
      })
    }
    else if(successfulAdds.length === 3){
      notify({
        body: <center>Added <b>{user.firstname} {user.lastname}</b> as a member of <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b></center>
      })
    }
    else if(successfulAdds.length > 3){
      notify({
        body: <center>Added <b>{user.firstname} {user.lastname}</b> as a member of <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} other groups</b></center>
      })
    }
    handleOnClose()
  }

  const userRequests = ((requests && requests.data) || [])
  const handleSendRequest = async (e, group) => {
    e.preventDefault()
    e.persist()
    logEvent({
      category: 'Request',
      action: `requesting to join group`
    })
    setSendRequest(group)
    try{
      const userList = await axios.get(`/api/groups/${group.group_id}/users`)
      if(userList.data.data.length !== 0){
        if( userList.data.data[0].users.find(u => u.username === user.username)){
          notify({
            body: <center><b>You</b> are already a member of this group</center>,
            dismissesOnAction: true,
          })
          setSendRequest('')
          return
        }
      }
      await axios.post(`/api/groups/${group.group_id}/requests/`, {username: user.username})//create request
      notify({body: <center>Access Requested</center>})//notify user
      await requestsRefetch()//get list of requests
      await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:user.username, member_group_name:null, group_name:group.group_id, 
        action:'make request', location:'profile'})
      setSendRequest('')
      await axios.post(`/api/groups/${group.group_id}/requests/${user.username}/send`)//send email
    }
    catch(error){
        console.log(error)
        setSendRequest('')
    }
  }

  const handleChange = (val) => {
    setMemberOfName(val)
  }

  const deleteFromList = (name) => {
    setAddList(addList => ([...(addList.filter(t => t.key !== name))]))
  }

  const addToAddList = (item) => {
    let temp = addList
    for(let t = 0; t < temp.length; t++){
      if(temp[t].key === item.name){
        notify({
          body: <center><b>{item.name}</b> is already in the list of groups to add</center>
        })
        return
      }
    }
    //if item.name is in addListAllowed.group_name, notify and return
    let foundInAdminGroups = addListAllowed.find(i => (i.group_name === item.name || i.name === item.name))
    if(!foundInAdminGroups){
      notify({
        body: <center>Access Denied: you are not an admin over <b>{item.name}</b></center>
      })
      return
    }
    temp.push(
      <Chip 
        className={styles.chip}
        key={item.name ? item.name : item.group_name} 
        label={item.name ? item.name : item.group_name} 
        trailingIcon="close" 
        onTrailingIconInteraction={() => deleteFromList(item.name ? item.name : item.group_name)} 
      />
    )
    setAddList(addList => ([...temp]))
    handleChange("")
    setMemberOfName("")
  }

  const handleDeleteRequest = async (e, group) => {
    e.preventDefault()
    e.persist()
    logEvent({
      category: 'Request',
      action: `deleting request to join group`
    })
    setSendRequest(group)
    try{
      await axios.delete(`/api/groups/${group.group_id}/requests/${user.username}`)
      await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:user.username, member_group_name:null, group_name:group.group_id, 
        action:'delete request', location:'profile'})
      await requestsRefetch()
      notify({body: <center>Request Deleted</center>})
      setSendRequest('')
    }
    catch(error){
      console.log(error)
      setSendRequest('')
    }
  }


  const handleUserDelete = async (ev, group_id, group_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({category: 'UserInfo',action: `delete user from group`})
    setDelUser(group_name)

    await axios.delete(`/api/groups/${group_id}/users/${user.username}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:user.username, member_group_name:null, group_name:group_id, 
      action:'delete user', location:'profile'})
    await myGroupsRefetch()
    setDelUser("")
    ev.stopPropagation()
    notify({
      body: <>Removed <b>{user.firstname} {user.lastname}</b> from <b>{group_id}</b></>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteUser(e, group_id)
        }
      ],
    })
  }

  const handleUndeleteUser = async (ev, group_id) => {
    ev.preventDefault()
    ev.persist()
    logEvent({category: 'UserInfo',action: `undo delete user`})
    const undoUsername = user.username
    const undoGroup = group_id
    const url = `/api/groups/${undoGroup}/users/${undoUsername}`
    await axios.post(url)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:undoUsername, member_group_name:null, group_name:undoGroup, 
      action:'undelete user', location:'profile'})
    await myGroupsRefetch()
    ev.stopPropagation()
  }

  const viewTab = (index) => {
    logEvent({
      category: 'GroupInfo',
      action: (index === 0 ? 'view members tab' : index === 1 ? 'view memberof tab' : 'view request tab')
    })
    setActiveTab(index)
  }

  return (
    <div className={styles.container}>
      <LogoutUser/>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {user.firstname + ' ' + user.lastname}&nbsp;
        </h1>
        <div className={styles.separator}></div>
        <TextField
          icon={{icon: 'filter_list', className: styles.searchIcons}}
          {...(search
            ? {
                trailingIcon: {
                  icon: 'close',
                  className: styles.searchIcons,
                  tabIndex: 0,
                  onClick: () => setSearch(''),
                },
              }
            : {})}
          label="Filter"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchInput}
          onClick={() => logEvent({category: 'Request', action: 'click on search bar'})}
        />
      </div>
      <p className={styles.muted}>This is your user page. Here, you can see the groups you are a part of and request to join other groups. 
      </p>   
      <TabBar className={styles.tab} activeTabIndex={activeTab} onActivate={(e) => viewTab(e.detail.index)}>
        <Tooltip content="See the list of groups you are a part of" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab className={styles.tabChild}>
             My Groups  {!myGroupsLoading && !myGroupsError && myGroups !== null &&<div className={styles.tabNumber}>{myGroups.data[0].groups.length}</div>}
          </Tab>
        </Tooltip>
        <Tooltip content="See the list of groups you can request access to" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab>
             Request Access   {!requestGroupsLoading && !requestGroupsError && requestGroups !== null && <div className={styles.tabNumber}>{requestGroups.data.groups.length}</div>}
          </Tab>
        </Tooltip>
      </TabBar>
      {activeTab === 0 ? (
        <GroupsTab
          loading={myGroupsLoading}
          list={groups}
          error={myGroupsError}
          navigate={navigate}
          search={search}
          setSearch={setSearch}
          handleUserDelete={handleUserDelete}
          user={user}
          notify={notify}
          delUser={delUser}
          location={location}
          setShowModal={setShowModal}
        />
      ):(
        <RequestsTab
          loading={requestGroupsLoading}
          list={requestGroupsList}
          error={requestGroupsError}
          navigate={navigate}
          search={search}
          setSearch={setSearch}
          handleSendRequest={handleSendRequest}
          handleDeleteRequest={handleDeleteRequest}
          sendRequest={sendRequest}
          setSendRequest={setSendRequest}
          userRequests={userRequests}
          user={user}
          notify={notify}
        />
      )}
        <NewMemberDialog
          open={showModal !== null}
          onClose={handleOnClose}
          onSubmit={handleSubmit}
          onChange={handleChange}
          value={memberOfName}
          list={modalGroupsList}
          type={showModal}
          posting={posting}
          error={addingError}
          addList={addList}
          addToAddList={addToAddList}
          userPage={true}
        />
    </div>
  )
}

const GroupsTab = ({loading, list, error, navigate, search, setSearch, handleUserDelete, user, notify, delUser, location, setShowModal}) => {

  const navigateGroupInfo = (g) => {
    logEvent({
      category: 'Profile',
      action: `navigate from profile to groupinfo page`
    })
    navigate("/admin/groups/" + g.group_id,{ state: {backNav: [{path:location.pathname, pageName:'Profile page'}], search:search }})
  }

  return(
    <div>
      <h3 className={styles.listHeader}>
        {user && user.roles && (user.roles.find((r) => r.role === "super-admin") || (user.roles.find((r) => r.role === "admin"))) && 
        <Button raised onClick={() => setShowModal(MEMBEROF)} disabled={error} className={styles.right}>
          Add Group
        </Button>
      }
      </h3>
      <div className={loading ? styles.loadingContainer : styles.listContainer} style={{height: list.length > 0 ? list.length * 80 : 80, minHeight:list.length < 3 ? list.length * 80 : 200}}>
        {error ? (
          <p>{error.message}</p>
        ) : !loading ? (
            <VirtualList
              list={list}
              icon={(g) => 
                (g.isAdmin || (user && user.roles && user.roles.find((r) => r.role === "super-admin"))) 
                ? 'clear'
                : ''
              }
              primaryText={(g) => g.group_name}
              secondaryText={(g) => (g.group_description ? g.group_description : ('ID: ' + g.group_id))}
              onIconClick={async (e, g) => await handleUserDelete(e, g.group_id, g.group_name)}
              onItemClick={(g) => 
                (g.isAdmin || (user && user.roles && user.roles.find((r) => r.role === "super-admin"))) 
                ? navigateGroupInfo(g)
                : 
                (user && user.roles && user.roles.find((r) => r.role === "admin")) ?
                notify({
                  body: <>Access Denied: You are not an admin over <b>{g.group_id}</b></>,
                })
                : ''
              }
              tooltipText1="Delete yourself from this group"
              itemKey={delUser}
              itemCompare='group_name'
              emptyListMessage={search ? 'No groups to show' : 'You are not a member of any groups'}
            />
        ) : (
          <CircularProgress/>
        )}
      </div>
    </div>
  )
}

const RequestsTab = ({loading, list, error, navigate, search, setSearch, handleSendRequest, handleDeleteRequest, sendRequest, setSendRequest, user, notify, userRequests}) => {
  return (
    <div className={loading ? styles.loadingContainer : styles.listContainer} style={{height: list.length > 0 ? list.length * 80 : 80, minHeight:list.length < 3 ? list.length * 80 : 200}}>
        {error ? (
          <p>{error.message}</p>
        ) : !loading ? (
          <VirtualList
            list={list}
            primaryText={(g) => g.group_name}
            secondaryText={(g) => (g.group_description ? g.group_description : ('ID: ' + g.group_id))}
            itemKey = {sendRequest}
            itemCompare='group_name'
            emptyListMessage='No Groups Exist'
            icon={(g) => userRequests.find((r) => r.group_name === g.group_id)? 'clear': 'add'}
            tooltipText1Opt2='Request access to this group'
            tooltipText1='Delete your request to access this group'
            disableHover
            onIconClick={(e,g) => userRequests.find((r) => r.group_name === g.group_id)?  handleDeleteRequest(e, g) : handleSendRequest(e,g)}
          />
        ) : (
          <CircularProgress size="xlarge" />
        )}
      </div>
  )
}