import axios from 'axios'

export const auth = dispatch => {
  axios
    .get('/api/auth')
    .then(({data: response}) => {
      if (!Object.keys(response).length) dispatch(clearAuth())

      dispatch(updateUser(response.user))
      dispatch(updateExpiration(response.expiration))
      dispatch(updateLastAuth(response.lastAuth))
    })
    .catch(err => {
      console.error(err)
    })
}

export const clearAuth = () => {
  return dispatch => {
    dispatch(updateUser(null))
    dispatch(updateExpiration(null))
    dispatch(updateLastAuth(null))
  }
}

export const updateUser = user => {
  return {
    type: 'UPDATE_LOGIN_USER',
    user: user
  }
}

export const updateExpiration = expiration => {
  return {
    type: 'UPDATE_LOGIN_EXPIRATION',
    expiration: expiration
  }
}

export const updateLastAuth = lastAuth => {
  return {
    type: 'UPDATE_LAST_AUTH',
    lastAuth: lastAuth
  }
}