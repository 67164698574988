import React, {useState} from 'react'
import {AutoSizer, List} from 'react-virtualized'
import styles from './VirtualList.module.css'
import {Icon} from '@rmwc/icon'
import {Tooltip} from '@rmwc/tooltip'
import '@rmwc/icon/icon.css'
import { CircularProgress } from '@rmwc/circular-progress'
import axios from 'axios'

export default function VirtualList({
  list,
  show,
  page,
  primaryText,
  secondaryText,
  middleText,
  onItemClick,
  icon,
  onIconClick,
  secondaryIcon,
  onSecondaryIconClick,
  disableHover,
  tooltipText1,
  tooltipText1Opt2,
  tooltipText2,
  itemKey,
  secondaryItemKey,
  itemCompare,
  emptyListMessage='Empty List',
  emptyOption,
  search,
  type
}) {
  const [requestedCount,setRequestedCount] = useState(0)
  const rowRenderer = ({index, key, style}) => {
    const item = list[index]
    if(page === 'groupsPage'){
      var getRequests = function() {
        return new Promise(function(resolve){
          setTimeout(function(){resolve(axios.get(`/api/groups/${item.group_id}/requests`))},1000)
        })
      }
      async function awaitRequests(){
        var result = await getRequests()
        if(result.data.data.length !== 0) item.requests = result.data.data.length
        if(requestedCount <= list.length) setRequestedCount(requestedCount+1)
      }
      awaitRequests()
    }
    
    const handleOnItemClick = (e) => {
      e.stopPropagation()
      if (onItemClick) onItemClick(item)
    }

    const handleOnIconClick = (e) => {
      e.stopPropagation()
      if (onIconClick) onIconClick(e, item)
      else handleOnItemClick(e)
    }

    const handleOnSecondaryIconClick = (e) => {
      e.stopPropagation()
      if (onSecondaryIconClick) onSecondaryIconClick(e, item)
      else handleOnItemClick(e)
    }

    const getIcon = name => {
      if (itemKey === name && name !== ""){//for deleting user/nested group from groups
        return <CircularProgress />
      }
      if (name.username && itemKey === name.username && name !== ""){//for accepting or denying requests on request tab in group
        return <CircularProgress />
      }

      if (itemKey.group_name !== undefined && itemKey.group_name === name && name !== ""){//for requesting access or deleting own request on access request page
        return <CircularProgress />
      }

      if (typeof(icon) === 'function'){
        return icon(item)
      }

      return icon
    }

    const getSecondaryIcon = name => {

      if (secondaryItemKey === name.username){
        return <CircularProgress />
      }

      if (typeof(secondaryIcon) === 'function'){
        return icon(item)
      }

      return secondaryIcon
    }
    
    return (
      <div
        key={key}
        onClick={handleOnItemClick}
        className={styles.item + (index > 0 ? ' ' + styles.line : '') + (disableHover ? '' : ' ' + styles.itemHover)}
        style={style}
      >
        <div className={middleText? styles.leftBox : styles.info}>
          {primaryText && 
          <h3 className={styles.primaryText}>{primaryText(item)}
            {item.requests && 
              <Tooltip content='Click to review user requests to join this group' align='right' activateOn='hover' showArrow enterDelay={500}>
                <div className={styles.requestNotif}>{item.requests}</div>
              </Tooltip>
            }
          </h3>}
          {secondaryText && 
            <p className={styles.secondaryText}>{secondaryText(item)}</p>
          }
        </div>
        <div className={styles.middleBox}>
          {middleText && 
            <p className={styles.middleText}>{middleText(item)}</p>
          }
        </div>
        <div className={middleText? styles.rightBox : styles.iconContainer}>
          { 
            secondaryIcon && <div className={styles.icon}>
            <Tooltip content={tooltipText2} align="right" activateOn="hover" showArrow enterDelay={500}>
              <Icon icon={getSecondaryIcon(item[itemCompare])} onClick={handleOnSecondaryIconClick}/>
            </Tooltip>
            </div>
          }
          {
            icon && <div className={styles.icon}>
              <Tooltip content={getIcon(item[itemCompare]) === 'add'? tooltipText1Opt2 : tooltipText1} align="right" activateOn="hover" showArrow enterDelay={500}>
                <Icon icon={getIcon(item[itemCompare])} onClick={handleOnIconClick}/>
              </Tooltip>
            </div>
          }
        </div>
      </div>
    )
  }
  if(list.length < 1){
    if(!emptyOption)
      return (<div className={styles.emptyList}>{emptyListMessage}</div>)
    else{
      return (
        <div className={styles.suggestionsRows} onClick={() => onItemClick({name: search, username: search, group_name: search, validated: false})}>
          &nbsp;&nbsp;&nbsp;<Icon icon={type === 'USER' ? "person_add" : "group_add"} className={styles.addIcon}></Icon>
          Add {type === 'USER' ? "user with netID" : "group with group name"} "{search}"<br/>
        </div>
      )
    }
  }

  return (
    <AutoSizer>
      {({width, height}) => (
        <List
          width={width}
          height={height}
          rowCount={list.length}
          rowHeight={show === false? 0 : page === 'modal' ? 48 : 80}
          rowWidth={width-8}
          rowRenderer={rowRenderer}
          className={styles.list}
        />
      )}
    </AutoSizer>
  )
}
