import React, {createContext} from 'react'

import { createSnackbarQueue, SnackbarQueue } from '@rmwc/snackbar'
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '../index.css'

// Create Context
export const SnackbarContext = createContext({})

// Provider Component
export const SnackbarProvider = ({children}) => {
  const {messages, notify} = createSnackbarQueue()

  return (
    <SnackbarContext.Provider
      value={{
        notify: (snack) => notify(snack)
      }}
      style={{background: '#0082FB'}}
    >
      {children}
      <SnackbarQueue messages={messages}/>
    </SnackbarContext.Provider>
  )
}
