import React, {useState, useContext} from 'react'
import { useGet } from "../../utils/_hooks"

import styles from './GlobalSearch.module.css'
import { AuthContext } from '../../context/AuthContext'
import parseName from '../../utils/parseName'

import {TextField} from '@rmwc/textfield'
import '@rmwc/textfield/styles'

import { MenuSurfaceAnchor, MenuSurface  } from "@rmwc/menu"
import '@material/menu/dist/mdc.menu.css'
import '@material/menu-surface/dist/mdc.menu-surface.css'

export default function GlobalSearch({location, navigate}){
  const {user} = useContext(AuthContext)
  const [globalSearch, setGlobalSearch] = useState('')
  const [active, setActive] = useState(false)

  const { data: adminGroupsData} = useGet(`/api/admin/permissions/${user.username}`)
  const { data: allGroupsData } = useGet('/api/groups/')
  const { data: allUsersData } = useGet('/api/users/')

  let adminGroups = adminGroupsData && adminGroupsData.data ? adminGroupsData.data : []//adminGroups is a list of the session user's admin groups
  let allGroups = allGroupsData && allGroupsData.data ? allGroupsData.data : []//allGroups is a list of all groups and their information
  let allUsers = allUsersData && allUsersData.data ? allUsersData.data : []//allUsers is a list of all users and their information

  const returnAdminGroups = (adminGroups || [])
  .map((g) => {
    return {
      group_guid: g.guid,
      group_name: parseName(g.group_name),
      group_id: g.group_name,
      group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
    }
  })
  .filter(
    (g) =>
      g.group_name.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      g.group_id.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )
  .filter((v,i,a)=>a.findIndex(t=>(t.group_name === v.group_name))===i)//dedup
  .sort((a, b) => {
    let _a = (a.group_id || '').toLowerCase()
    let _b = (b.group_id || '').toLowerCase()
    return _a > _b ? 1 : -1
  })
  .sort((a, b) => {
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return 1;
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return -1;
    return 0
  })

  const returnAllGroups = (allGroups || [])
  .map((g) => {
    return {
      group_guid: g.guid,
      group_name: parseName(g.name),
      group_id: g.name,
      group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
    }
  })
  .filter(
    (g) =>
      g.group_name.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      g.group_id.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )
  .filter((v,i,a)=>a.findIndex(t=>(t.group_name === v.group_name))===i)//dedup
  .sort((a, b) => {
    let _a = (a.group_id || '').toLowerCase()
    let _b = (b.group_id || '').toLowerCase()
    return _a > _b ? 1 : -1
  })
  .sort((a, b) => {
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return 1;
    if (a.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_name.toUpperCase().indexOf(globalSearch.toUpperCase()) || a.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.group_id.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return -1;
    return 0
  })

  const returnAllUsers = (allUsers || [])
  .map((u) => {
    return {
      email: u.email,
      firstname: u.firstname,
      lastname: u.lastname,
      name: u.firstname + ' ' + u.lastname,
      guid: u.guid,
      last_session_datetime: u.last_session_datetime,
      username: u.username,
      uuid: u.uuid
    }
  })
  .filter(
    (u) =>
      u.name.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      u.username.match(new RegExp(globalSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )
  .filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
  .sort((a, b) => {
    let _a = (a.username || '').toLowerCase()
    let _b = (b.username || '').toLowerCase()
    return _a > _b ? 1 : -1
  })
  .sort((a, b) => {
    if ((a.name && b.name && a.name.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.name.toUpperCase().indexOf(globalSearch.toUpperCase()) )
    || a.username.toUpperCase().indexOf(globalSearch.toUpperCase()) > b.username.toUpperCase().indexOf(globalSearch.toUpperCase()))
      return 1;
      if ((a.name && b.name && a.name.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.name.toUpperCase().indexOf(globalSearch.toUpperCase()) )
      || a.username.toUpperCase().indexOf(globalSearch.toUpperCase()) < b.username.toUpperCase().indexOf(globalSearch.toUpperCase()))
        return -1;
    return 0
  })

  let topSevenUsers = returnAllUsers.slice(0, 7)
  let topSevenGroups
  if (!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')) {
    topSevenGroups = returnAdminGroups.slice(0, 7)
  }
  else topSevenGroups = returnAllGroups.slice(0, 7)

  const navigatePage = (input, type) => {
    if(globalSearch !== '' && globalSearch !== "\n"){
      setGlobalSearch('')
      let pathname = location.pathname
      let tempObj = {path:pathname}
      let backNavArray = []

      if(pathname === '/superadmin' || pathname === '/superadmin/') tempObj.pageName = "Super-Admin Page"
      else if(pathname === '/admin/') tempObj.pageName = "Admin Page"
      else if(pathname === '/profile/') tempObj.pageName = "Profile Page"
      else if(pathname.startsWith('/superadmin/')) tempObj.pageName = parseName(pathname.replace('/superadmin/',''))
      else if(pathname.startsWith('/admin/groups/')) tempObj.pageName = parseName(pathname.replace('/admin/groups/',''))
      else if(pathname.startsWith('/admin/users/')) tempObj.pageName = pathname.replace('/admin/users/','')
      else if(pathname.startsWith('/admin/results/')) tempObj.pageName = "Results"
      
      if(location.state.backNav){
        backNavArray = location.state.backNav
      }

      backNavArray.push(tempObj)

      if(type === 'user') navigate(`/admin/users/${input}`,{ state: {backNav:backNavArray, search:location.state.search, isFromGlobal:true}})
      else if(type === 'group') navigate(`/admin/groups/${input}`,{ state: {backNav:backNavArray, search:location.state.search, isFromGlobal:true}})
      else if(type === 'results') navigate(`/admin/results/${globalSearch}`,{ state: {backNav:backNavArray, search:location.state.search, globalSearch:globalSearch, isFromGlobal:true}})
    }
  }

  const keyPress = (k) => {
    if(k.keyCode === 13){
      setGlobalSearch(globalSearch.substring(0, globalSearch.length - 1))
      navigatePage('results','results')
   }
  }

  return (
    <div className={styles.globalContainer} >
      <MenuSurfaceAnchor className={styles.listContainer} >
        <MenuSurface anchorCorner='bottomLeft' open={true} className={styles.listContainer}>
          {active && globalSearch?
            <div className={styles.listBox} styles={{height: globalSearch === '' ? 0 : ''}}>
              {globalSearch && (topSevenUsers.length > 0 || topSevenGroups.length > 0) &&
                <div>
                  {topSevenUsers.length > 0 ? 
                    <div className={styles.suggestionsBox}>
                      <div style={{marginBottom:5, marginTop:3}}>Users</div>
                      {topSevenUsers.map((u) => 
                        <div key={u.username} onClick={() => navigatePage(u.username, 'user')} className={styles.suggestionsRows}>
                          &nbsp;&nbsp;&nbsp;{u.name} ({u.username})<br/>
                        </div>
                      )}
                    </div>
                    :
                    <div className={styles.suggestionsBox}>
                      No Users
                    </div>
                  }
                  <hr/>
                  {topSevenGroups.length > 0 ? 
                    <div className={styles.suggestionsBox}>
                      <div style={{marginBottom:5, marginTop:3}}>Groups</div>
                      {topSevenGroups.map((g) => 
                        <div key={g.group_id} onClick={() => navigatePage(g.group_id, 'group')} className={styles.suggestionsRows}>
                          &nbsp;&nbsp;&nbsp;{g.group_name} ({g.group_id})<br/>
                        </div>
                      )}
                    </div>
                    :
                    <div className={styles.suggestionsBox}>
                      No Groups
                    </div>
                  }
                </div>
              }
              {globalSearch && topSevenUsers.length === 0 && topSevenGroups.length === 0 &&
                <div className={styles.noResultsMessage}>
                  No results for "{globalSearch}". Check your spelling or try searching something else. 
                </div>
              }
            </div>
          :
            <></>
          }
        </MenuSurface>
        <TextField
          onKeyDown={(k) => keyPress(k)}
          trailingIcon={{icon:"search", className: styles.searchIcons, tabIndex: 0, onClick:() => navigatePage('results','results')}}
          label={<div className={styles.textFieldLabel}>Search</div>}
          value={globalSearch}
          onChange={(e) => {setActive(true); setGlobalSearch(e.target.value)}}
          className={styles.searchInput}
        />
      </MenuSurfaceAnchor>
    </div>
  )
}