import React from 'react';
import styles from './Login.module.css'

import { Button } from '@rmwc/button'
import '@material/button/dist/mdc.button.css';

export default function Login() {
  const handleClick = () => {
    window.location.href = "/login"
  }

  return (
    <div className={styles.Login}>
      <h1>Please login to continue</h1>
      <Button raised onClick={handleClick}>LOGIN</Button>
    </div>
  )
}