import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {AuthProvider} from './context/AuthContext'
import {SnackbarProvider} from './context/SnackbarContext'

ReactDOM.render(
  <AuthProvider>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </AuthProvider>,
  document.getElementById('root')
)
