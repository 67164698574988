import React, {useState, useContext} from 'react'
import styles from './SuperAdmin.module.css'
import {useGet, logEvent} from '../../utils/_hooks'
import {CircularProgress} from '@rmwc/circular-progress'
import {TextField} from '@rmwc/textfield'
import VirtualList from '../../components/VirtualList/VirtualList'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import parseName from '../../utils/parseName'
import {AuthContext} from '../../context/AuthContext'
import NoAccess from '../NoAccess/NoAccess'

import { Tooltip } from "@rmwc/tooltip"
import "@rmwc/tooltip/tooltip.css"
import { TabBar, Tab } from "@rmwc/tabs"
import "@rmwc/tabs/styles"
import '@rmwc/circular-progress/styles'
import '@rmwc/textfield/styles'
import '@rmwc/dialog/styles'
import '@rmwc/tooltip/tooltip.css'

export default function SuperAdmin({location, navigate}) {
  const [search, setSearch] = useState('')
  const [searchSet, setSearchSet] = useState(false)
  const {user} = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState(0)
  const { data: groupData, error: groupError, loading: groupLoading } = useGet('/api/groups/')
  const { data: userData, error: userError, loading: userLoading } = useGet(`/api/users/`)

  if(!searchSet && location.state.search && location.state.isBack){
    setSearch(location.state.search)
    setSearchSet(true)
  }

  if (!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')) {
    return <NoAccess />
  }

  const groups = ((groupData && groupData.data) || [])
    .map((g) => {
      return {
        group_guid: g.guid,
        group_name: parseName(g.name),
        group_id: g.name,
        group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
      }
    })
    .filter(
      (g) =>
        g.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
        g.group_id.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
    )
    .sort((a, b) => {
      let _a = (a.group_id || '').toLowerCase()
      let _b = (b.group_id || '').toLowerCase()
      return _a > _b ? 1 : -1
    })

  const users = ((userData && userData.data) || [])
    .map((u) => {
      return {
        user_guid: u.guid,
        username: u.username,
        firstname: u.firstname,
        lastname: u.lastname
      }
    })
    .filter(
      (u) =>
        u.username.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
        (u.firstname + " " + u.lastname).match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
    )

  const viewTab = (index) => {
    logEvent({
      category: 'GroupInfo',
      action: (index === 0 ? 'view members tab' : index === 1 ? 'view memberof tab' : 'view request tab')
    })
    setActiveTab(index)
  }

  return (
    <div className={styles.container}>
      <LogoutUser/>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Super Admin&nbsp;
        </h1>
        <div className={styles.separator}></div>
        <TextField
          icon={{icon: 'filter_list', className: styles.searchIcons}}
          {...(search
            ? {
                trailingIcon: {
                  icon: 'close',
                  className: styles.searchIcons,
                  tabIndex: 0,
                  onClick: () => setSearch(''),
                },
              }
            : {})}
          label="Filter"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchInput}
        />
      </div>
      <p className={styles.muted}>
        As a super-admin, you can use this page to administrate over any group or user. 
      </p>
      <TabBar  className={styles.tab} activeTabIndex={activeTab} onActivate={(e) => viewTab(e.detail.index)}>
        <Tooltip content="See the list of all groups" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab className={styles.tabChild}>
            Groups {!groupLoading && !groupError && <div className={groups.length >= 100? styles.tabNumberLarge : styles.tabNumber}>{groups.length}</div>}
          </Tab>
        </Tooltip>
        <Tooltip content="See the list of all users" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab>
            Users {!userLoading && !userError && <div className={users.length >= 100? styles.tabNumberLarge : styles.tabNumber}>{users.length}</div>}
          </Tab>
        </Tooltip>
      </TabBar>
        {activeTab === 0 ? (
        <GroupsTab
          loading={groupLoading}
          groups={groups}
          error={groupError}
          navigate={navigate}
          search={search}
          setSearch={setSearch}
          location={location}
        />
      ):(
        <UsersTab
          loading={userLoading}
          users={users}
          error={userError}
          navigate={navigate}
          search={search}
          setSearch={setSearch}
          location={location}
        />
      )}
      
    </div>
  )
}

const GroupsTab = ({loading, groups, error, navigate, search, setSearch, location}) => {

  const navAdminInfo = (id) => {
    logEvent({
      category: 'SuperAdmin',
      action: `navigate to admininfo page`
    })
    navigate(id,{ state:{backNav: [{path:location.pathname, pageName:'Super-Admin page'}], search:search }})
  }

  return (
    <div>
      <div className={loading ? styles.loadingContainer : styles.listContainer} style={{height: groups.length > 0 ? groups.length * 80 : 80, minHeight:groups.length < 3 ? groups.length * 80 : 200}}>
        {error ? (
          <p>{error.message}</p>
        ) : !loading ? (
          <VirtualList
            list={groups}
            icon='keyboard_arrow_right'
            primaryText={(g) => g.group_name + '  (' + g.group_id + ')'}
            secondaryText={(g) => (g.group_description ? g.group_description : '')}
            onItemClick={(g) => navAdminInfo(g.group_id)}
            tooltipText1="See the admins in this group"
            itemKey=''
            itemCompare='group_name'
            emptyListMessage='No Groups Exist'
          />
        ) : (
          <CircularProgress size="xlarge" />
        )}
      </div>
    </div>
  )
}

const UsersTab = ({loading, users, error, navigate, search, setSearch, location}) => {
  
  const navUserInfo = (id) => {
    logEvent({
      category: 'SuperAdmin',
      action: `navigate to userinfo page`
    })
    navigate('/admin/users/' + id,{ state: {backNav: [{path:location.pathname, search:search, pageName:'Super-Admin page'}], search:search }})
  }

  return (
    <div>
      <div className={loading ? styles.loadingContainer : styles.listContainer} style={{height: users.length > 0 ? users.length * 80 : 80, minHeight:users.length < 3 ? users.length * 80 : 200}}>
        {error ? (
          <p>{error.message}</p>
        ) : !loading ? (
          <VirtualList
            list={users}
            icon={'keyboard_arrow_right'}
            primaryText={(u) => u.firstname + " " + u.lastname}
            secondaryText={(u) => u.username}
            onItemClick={(u) => navUserInfo(u.username)}
            tooltipText1="See the information for this group"
            itemKey=''
            itemCompare='username'
            emptyListMessage='No Users Exist'
          />
        ) : (
          <CircularProgress size="xlarge" />
        )}
      </div>
    </div>
  )
}