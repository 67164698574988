import React, {useState, useContext} from 'react'
import {useGet, logEvent} from '../../utils/_hooks'

import NewMemberDialog from '../../components/NewMemberDialog/NewMemberDialog'
import styles from './AdminInfo.module.css'
import parseName from '../../utils/parseName'
import axios from 'axios'
import VirtualList from '../../components/VirtualList/VirtualList'
import { AuthContext} from "../../context/AuthContext"
import NoAccess from '../NoAccess/NoAccess'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import { Chip } from '@rmwc/chip'
import '@rmwc/chip/styles';
import Avatar from '../../components/Avatar'
import {CircularProgress} from '@rmwc/circular-progress'
import '@rmwc/circular-progress/circular-progress.css'

import {Button} from '@rmwc/button'
import '@material/button/dist/mdc.button.css'

import {Icon} from '@rmwc/icon'
import '@rmwc/icon/icon.css'

import {SnackbarContext} from '../../context/SnackbarContext'

const ADMIN = 'USER'
export default function AdminInfo({group_name, location, navigate}) {
  const { user } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [showModal, setShowModal] = useState(null)
  const [posting, setPosting] = useState(false)
  const [addingError, setAddingError] = useState(false)
  const [delUser, setDelUser] = useState('')
  const [modalUsersList,setModalUsersList] = useState([])
  const {notify} = useContext(SnackbarContext)
  const [addList, setAddList] = useState([])

  const { data: allUsersData } = useGet('/api/users/')
  const {data: adminData, loading: adminLoading, refetch: adminRefetch, error: adminError} = useGet(
    `/api/admin/permissions/groups/${group_name}`
  )
  const { data: groupData } = useGet(
    `/api/groups/${group_name}`
  )

  if (!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')) {
    return <NoAccess />
  }

  let allUsers = allUsersData && allUsersData.data ? allUsersData.data : []

  const handleOnClose = () => {
    setShowModal(null)
    setAddList([])
    setUsername('')
    setAddingError(false)
  }

  const deleteFromList = (name) => {
    setAddList(addList => ([...(addList.filter(t => t.key !== name))]))
  }

  const getList = () => {
    return modalUsersList
  }

  const addToAddList = (item) => {
    let temp = addList
    for(let t = 0; t < temp.length; t++){
      if(temp[t].key === item.username){
        notify({
          body: <center><b>{item.username}</b> is already in the list of users to add</center>
        })
        return
      }
    }
    temp.push(
      <Chip 
        className={styles.chip}
        icon={item.validated === false ? '' : <Avatar className={styles.UserIcon} size="medium" user={item}/>}
        key={item.username} 
        label={<div style={{marginLeft:'5px'}}>{item.username}</div>} 
        trailingIcon="close" 
        onTrailingIconInteraction={() => deleteFromList(item.username)} 
      />
    )
    setAddList(addList => ([...temp]))
    handleChange("")
    setUsername("")
  }

  const handleSubmit = async () => {
    logEvent({
      category: 'Admininfo',
      action: `add user as admin over group`
    })
    setPosting(true)
    setAddingError(false)
    if(username.length > 0){
      setPosting(false)
      setAddingError(true)
      return
    }
    let tempName
    let successfulAdds = []
    for(let count = 0; count < addList.length; count++){
      try {
        let tempArray = modalUsersList
        tempArray = tempArray.filter(u => u.username !== addList[count].key)
        tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
        setModalUsersList(tempArray)//removes the user from the list of users to show in the modal
        tempName = addList[count].key
        await axios.post(`/api/admin/permissions/add/${tempName}/${group_name}`)
        const adminRoles = await axios.get(`/api/users/${tempName}/roles`)
        let result = adminRoles.data
        if(result.data === undefined){
          await axios.post(`/api/users/${tempName}/roles`, {role: 'admin'})
        }
        else{
          result = result.data.roles.find(t => t.role === 'admin')
          if(result === undefined){
            await axios.post(`/api/users/${tempName}/roles`, {role: 'admin'})
            }
        }
        await adminRefetch()
        //add to activity history
        await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:tempName, member_group_name:null, group_name:group_name, 
          action:'add admin', location:'admin info'})
        successfulAdds.push(addList[count].key)
      }
      catch (error) {
        if(adminData.data.admins.find(u => u.username === addList[count].key)){
          notify({
            body: <center>Error: <b>{tempName}</b> is already an admin over <b>{group_name}</b></center>
          })
          console.error(error)
        }
        else{
          notify({
            body: <center>Error: failed to add <b>{tempName}</b> as an admin over <b>{group_name}</b></center>
          })
          console.error(error)
        }
      }
    }
    setPosting(false)
    if (successfulAdds.length === 1){
      notify({
        body: <center>Added <b>{successfulAdds[0]}</b> as an admin over <b>{group_name}</b></center>
      })
    }
    else if(successfulAdds.length === 2){
      notify({
        body: <center>Added <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b> as admins over <b>{group_name}</b></center>
      })
    }
    else if(successfulAdds.length === 3){
      notify({
        body: <center>Added <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b> as admins over <b>{group_name}</b></center>
      })
    }
    else if(successfulAdds.length > 2){
      notify({
        body: <center>Added <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} others</b> as admins over <b>{group_name}</b></center>
      })
    }
    handleOnClose()
  }

  const handleChange = (val) => {
    if (showModal === ADMIN) setUsername(val)
  }

  const getValue = () => {
    if (showModal === ADMIN) return username
    return ''
  }

  const handleUserDelete = async (ev, username, admin) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'AdminInfo',
      action: `delete user as admin over group`
    })
    setDelUser(username)
    
    let tempArray = modalUsersList
    tempArray.push(admin)
    tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
    setModalUsersList(tempArray)

    const adminDeleted = await axios.delete('/api/admin/permissions', {data: {username: username, group_name: group_name}})
    const adminGroups = await axios.get(`/api/admin/permissions/${adminDeleted.data.data.username}`)
    if(adminGroups.data.data.length === 0){
      await axios.delete(`/api/users/${username}/roles/admin`) 
    }
    //add to activity history
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:group_name, 
      action:'delete admin', location:'admin info'})
    await adminRefetch()
    setDelUser('')
    ev.stopPropagation()
    notify({
      body: <>Removed <b>{admin.firstname} {admin.lastname}</b> as an admin from this group</>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteUser(e, admin.username)
        }
      ],
    })
  }

  const handleUndeleteUser = async (ev, username) => {
    ev.preventDefault()
    ev.persist()
    let tempArray = modalUsersList
    tempArray = tempArray.filter(u => u.username !== username)
    tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup
    setModalUsersList(tempArray)//removes the user from the list of users to show in the modal

    const undoUsername = username
    const undoGroup = group_name
    await axios.put('/api/admin/permissions', {username: undoUsername, group_name: undoGroup})
    const adminUndeleteRoles = await axios.get(`/api/users/${undoUsername}/roles`)
    let result = adminUndeleteRoles.data
      if(result.data === undefined){
         await axios.post(`/api/users/${username}/roles`, {role: 'admin'})
      }
      else{
        result = result.data.roles.find(t => t.role === 'admin')
        if(result === undefined){
          await axios.post(`/api/users/${username}/roles`, {role: 'admin'})
          }
      }
      //add to activity history
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:undoUsername, member_group_name:null, group_name:undoGroup, 
      action:'undelete admin', location:'admin info'})
    await adminRefetch()
    ev.stopPropagation()
  }

  const admins = adminData && adminData.data ? adminData.data.admins : []
  const thisGroup = groupData && groupData.data ? groupData.data : []
  if(allUsers.length > 0){
    for(let u = 0; u < admins.length; u++){
      allUsers = allUsers.filter(au => (au ? (au.username !== admins[u].username) : false))
    }
    if(modalUsersList.length === 0) setModalUsersList(modalUsersList => [...allUsers])
  }//allUsers now has the list of all users except those who are already admins over this group

  const navigateGroupInfo = (group_name) => {
    logEvent({
      category: 'AdminInfo',
      action: `navigate to groupinfo page`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:parseName(group_name)})
    navigate(`/admin/groups/${group_name}`,{ state: {backNav:backNavArray, search:location.state.search }})
  }
  const addAdmin = () => {
    logEvent({
      category: 'AdminInfo',
      action: `clicked add admin button`
    })
    
    setShowModal(ADMIN)
  }
  const navigateUser = (username) => {
    logEvent({
      category: 'AdminInfo',
      action: `navigate to userinfo page`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:parseName(group_name)})
    navigate(`/admin/users/${username}`,{ state: {backNav:backNavArray, search:location.state.search }})
  }

  const backButton = () => {
    let tempNav = location.state.backNav[location.state.backNav.length-1].path
    let tempArr = location.state.backNav
    tempArr.pop()
    navigate(tempNav,{ state: {backNav:tempArr, search:location.state.search, isBack:true }})
  }

  return (
    <div className={styles.container}>
      <LogoutUser/>
      <Button  icon="arrow_back" onClick={() => backButton()}>
        Back to {location.state.backNav[location.state.backNav.length-1].pageName}
      </Button>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {parseName(group_name) + ' Group'}
          <span className={styles.admin}>
            <Icon
              icon={{icon: 'supervisor_account', size: 'xsmall'}}
              className={styles.adminIcon}
            />
            Admins
          </span>
        </h2>
        <div className={styles.spacer} />
        <Button outlined icon="info" onClick={() => navigateGroupInfo(group_name)}>
          Details
        </Button>
      </div>
      <p className={styles.subTitle}>ID: {group_name}</p>
      {thisGroup.description? <p className={styles.subTitle}>{thisGroup.description.replace(/^{"/,"").replace(/"}$/,"")}</p> : <></>}
      <div className={styles.listHeader}>
        <h3>
          Administrators{' '}
          {!adminLoading && !adminError && (
            <span className={styles.adminNumber}>{(admins || []).length}</span>
          )}
        </h3>
        <Button raised onClick={() => addAdmin()}>
          Add Admin
        </Button>
      </div>
      {!adminLoading ? (
        <div
          className={thisGroup.description? styles.groupsContainerWithDescription : styles.groupsContainer}
          style={{height: admins.length > 0 ? admins.length * 80 : 80}}
        >
          <VirtualList
            list={admins || []}
            primaryText={(a) => a.firstname + ' ' + a.lastname}
            secondaryText={(a) => a.username}
            icon="clear"
            tooltipText1="Remove this user's admin permissions to this group." 
            onItemClick = {(u) => navigateUser(u.username) }
            onIconClick={async (e, a) => await handleUserDelete(e, a.username, a)}
            itemKey={delUser}
            itemCompare="username"
            emptyListMessage="There are currently no administrators in this group."
          />
        </div>
      ) : (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      <NewMemberDialog
        open={showModal !== null}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        onChange={handleChange}
        value={getValue()}
        list={getList()}
        type={showModal}
        posting={posting}
        error={addingError}
        addList={addList}
        addToAddList={addToAddList}
      />
    </div>
  )
}
