import React from "react"
import { Button } from "@rmwc/button"
import {Dialog} from '@rmwc/dialog'
import '@material/dialog/dist/mdc.dialog.css'
import styles from './ChangeLog.module.css'
import gitlabIcon from './gitlab_icon.svg'
// import {Icon} from '@rmwc/icon'

export default function ChangeLog({ open, onClose, currentVersion }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <div className={styles.changelog}>
        <h2 className={styles.title}>
          What's New in Version
          <span className={styles.currentVersion}>{currentVersion}</span>
          <div className={styles.readMore}>
            <img src={gitlabIcon} alt="Gitlab"/>
            <a target="_blank" rel="noopener noreferrer" href="https://git.physics.byu.edu/csr-office/ad-group-manager/tags">Full History</a>
          </div>
        </h2>
        <div className={styles.content}>
          <div className={styles.imgContent}>
          <img src="/globalSearch.gif" alt="demo" />
          <img src="/backButton.gif" alt="demo" />
          </div>
          {/* New Features section */}
          <h3 className={styles.blueHeader}><span role="img" aria-label="smile">😍</span> New Features</h3>
          <ul>
            <li><strong>Global Search: </strong> Admins can now use the global search at the top of the page for quicker navigation. </li>
            <li><strong>Back Button: </strong> Now navigate back faster and more easily, all while preserving filters from previous pages. </li>
          </ul>

          {/* Improvements section */}
          <h3 className={styles.yellowHeader}><span role="img" aria-label="rocket">🚀</span> Improvements</h3>
          <ul>
            <li>When you add a user who is already in a group, error messages are now more specfic and helpful. </li>
            <li>User and group counts are now included in the members tab on group pages. </li>
            <li>Filtering on the admin page also filters the request list. </li>
          </ul>

          {/* Bug fixes section */}
          <h3 className={styles.redHeader}><span role="img" aria-label="bug">🐞</span> Bug Fixes</h3>
          <ul>
            <li>Coloring on tabs, notifications, and search bars are now more obvious. </li>
            <li>Minor styling changes. </li>
            <li>Fixed a false negative error when requesting to join a group. </li>
          </ul>
        </div>
        <div className={styles.actions}>
          <Button  raised onClick={onClose}>OK</Button>
        </div>
      </div>
    </Dialog>
  )
}
