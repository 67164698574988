import React, { useState, useContext, useEffect } from "react"
import { useNavigate, useLocation } from "@reach/router"
import "@material/drawer/dist/mdc.drawer.css"
import styles from "./Sidebar.module.css"
import {Icon} from '@rmwc/icon'
import {Tooltip} from '@rmwc/tooltip'
import "@rmwc/icon/icon.css"
import ChangeLog from "../ChangeLog/ChangeLog"
import { AuthContext } from "../../context/AuthContext"
import appVersion from "../../utils/getAppVersion"
import {logEvent} from '../../utils/_hooks'
import axios from 'axios'

export default function Sidebar({ open }) {
  const [changeLogOpen, setChangeLogOpen] = useState(false)
  const { user } = useContext(AuthContext)
  let last_session = new Date(user.last_session_datetime)
  let versionrelease = new Date("06/26/2020")//set release date here
  let today = new Date()
  let days_since_release = (Math.ceil((today.getTime() - versionrelease.getTime())/(1000*3600*24)))
  let days_since_login = (Math.ceil((today.getTime() - last_session.getTime())/(1000*3600*24)))


  useEffect(() => {
    if(days_since_release <= 14) {//the number there <<< is the number of days since the release that we want to show the popup
      if(days_since_login > days_since_release){
        setChangeLogOpen(true)
      }
    }
    else if(days_since_login > days_since_release){
      setChangeLogOpen(true)
    }
  },[days_since_login, days_since_release])

  const openLog = () => {
    logEvent({category: 'Changelog', action: 'click on changelog icon'})
    setChangeLogOpen(true)
  }

  async function closeLog(username){
    setChangeLogOpen(false)
    try{
      await axios.put(`/api/users/${username}/session`, {username: username})
    }
    catch(err){
      console.log(err)
    }
  }
//
  return (
    <div className={open ? styles.sidebarOpen : styles.sidebar}>
      <div className={styles.sidebarScrim} />
      <div className={styles.sidebarInternal}>
        {user && user.roles && user.roles.find((r) => r.role === "super-admin") && (
          <SidebarItem name="SuperAdmin" icon="lock" match="^/superadmin" nav="/superadmin/"/>
        )}
        {user && user.roles && user.roles.find((r) => r.role === "admin") && (
          <SidebarItem name="Admin" icon="security" match="^/admin" nav="/admin/" />
        )}
        <SidebarItem name="Profile" icon="person" match="^/profile" nav="/profile/" />
        
        <div className={styles.sidebarBottom}>
          <div className={styles.sidebarDivider} />
            <a className={styles.noUnderline} href="https://forms.clickup.com/f/26j0r-2968/7HUAEX1FC849H0ZPZM" target="_blank" rel="noopener noreferrer">
              <SidebarItem
                name="Report an Issue"
                icon="bug_report"
                onClick={() => logEvent({category: 'Bug', action: 'clicked on bug report link'})}
              />
            </a>
            <SidebarItem
              name="See What's New!"
              icon="event_note"
              tag={appVersion}
              nav="test"
              onClick={() => openLog()}
            />
          <ChangeLog open={changeLogOpen} currentVersion={appVersion} onClose={() => closeLog(user.username)} />
        </div>
      </div>
    </div>
  )
}

function SidebarItem({ name, icon, match, nav, onClick, tag, notifBadge, user }) {
  const history = useNavigate()
  const { pathname } = useLocation()
  let selected = false
  let profilePath = "/users/" + user

  if(name === "Users" && pathname === profilePath ){
    selected = false
  }
  else{
    selected = match && new RegExp(match).test(pathname)
  }

  const navigate = () => {
    // If given an onclick, do the onclick
    if (onClick && typeof onClick === "function") onClick()
    // Otherwise navigate
    else {
      logEvent({category: name, action: `navigate to ${name}`})
      history(nav, {state:{isBack:false}})
    }
  }

  return (
    <Tooltip content={name} align="right" activateOn="hover" showArrow enterDelay={500}>
      <div className={styles.sidebarItem + (selected ? " " + styles.selectedItem : "")}>
        <div onClick={navigate}>
          <Icon icon={icon} outlined='true'/>
          {notifBadge && <div className={styles.notifBadge} />}
          <span>{name}</span>
          {tag && <div className={styles.tag}>{tag}</div>}
        </div>
      </div>
    </Tooltip>
  )
}
