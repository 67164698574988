import React, {useState, useContext} from 'react'
import axios from "axios"

import styles from './UserInfo.module.css'
import {useGet, logEvent} from '../../utils/_hooks'
import VirtualList from '../../components/VirtualList/VirtualList'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import parseName from '../../utils/parseName'
import {AuthContext} from '../../context/AuthContext'
import NewMemberDialog from "../../components/NewMemberDialog/NewMemberDialog"
import NoAccess from '../NoAccess/NoAccess'

import { Chip } from '@rmwc/chip'
import '@rmwc/chip/styles';

import { Button } from "@rmwc/button"
import "@material/button/dist/mdc.button.css"

import { TabBar, Tab } from "@rmwc/tabs"
import "@rmwc/tabs/styles"

import { Dialog } from '@rmwc/dialog';
import '@material/dialog/dist/mdc.dialog.css';

import {SnackbarContext} from '../../context/SnackbarContext'
import {CircularProgress} from '@rmwc/circular-progress'
import '@rmwc/circular-progress/styles'

import { Tooltip } from "@rmwc/tooltip"
import '@rmwc/tooltip/tooltip.css'

const MEMBEROF = "MEMBEROF"
const ADMINGROUP = 'ADMINGROUP'

export default function UserInfo({ username, navigate, location}) {
  const {user} = useContext(AuthContext)
  const [showModal, setShowModal] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const [memberOfName, setMemberOfName] = useState("")
  const [adminName, setAdminName] = useState("")
  const [addingError, setAddingError] = useState(false)
  const [posting, setPosting] = useState(false)
  const [delUser, setDelUser] = useState("")
  const {notify} = useContext(SnackbarContext)
  const [modalGroupsList,setModalGroupsList] = useState([])
  const [modalAdminGroupsList,setModalAdminGroupsList] = useState([])
  const [addList, setAddList] = useState([])
  const [deleteAllOpen, setDeleteAllOpen] = useState(false)
  const [removeAll, setRemoveAll] = useState(false)
  const [adminGroupsState, setAdminGroupsState] = useState([])
  const [userGroupsState, setUserGroupsState] = useState([])

  const {data, error, loading, refetch} = useGet(`/api/users/${username}/groups`)
  //this user's info, including groups

  const {data: userOnlyData} = useGet(`/api/users/${username}`)
  //this user's info only, no groups

  const { data: userAdminGroupsData, loading:userAdminGroupsLoading, error:userAdminGroupsError, refetch: userAdminGroupsRefetch} = useGet(`/api/admin/permissions/${username}`)
  //this user's admin groups
  const { data: adminGroupsData} = useGet(`/api/admin/permissions/${user.username}`)
  //session user's admin groups
  const { data: allGroupsData } = useGet('/api/groups/')

  let allGroups = allGroupsData && allGroupsData.data ? allGroupsData.data : []
 
  let userAdminGroups = ((userAdminGroupsData && userAdminGroupsData.data ? userAdminGroupsData.data : []) || [])
    .map((g) => {
      return {
        group_guid: g.guid,
        group_name: parseName(g.group_name),
        group_id: g.group_name,
        group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
      }
    })
    .sort((a, b) => {
      let _a = (a.group_id || '').toLowerCase()
      let _b = (b.group_id || '').toLowerCase()
      return _a > _b ? 1 : -1
    })

  const adminGroups = adminGroupsData && adminGroupsData.data ? adminGroupsData.data : []
  const userInfo = ((data && data.data && data.data[0]? data.data[0] : []) || [])
  const userOnlyInfo = ((userOnlyData && userOnlyData.data? userOnlyData.data : {}) || {})

  const userGroups = (userInfo && userInfo.groups ? userInfo.groups : [])

  if(adminGroupsState && adminGroupsState.length === 0 && userAdminGroups.length !== 0) setAdminGroupsState(adminGroupsState => [...userAdminGroups])
  if(userGroupsState && userGroupsState.length === 0 && userGroups.length !== 0) setUserGroupsState(userGroupsState => [...userGroups])

  //adminGroups is the list of groups that the session user is an admin over
  //userInfo.groups is the list of groups that the user whose page we are looking at is a member of
  let tempGroups = []
  let tempGroups1 = []
  if(!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')){
    tempGroups = adminGroups
  }
  else{
    tempGroups = allGroups
    tempGroups1 = allGroups
  }

  if(userInfo && userInfo.groups && userInfo.groups.length !== 0 && tempGroups.length > 0){
    for(let g = 0; g < userInfo.groups.length; g++){
      tempGroups = tempGroups.filter(ag => (ag ? (ag.group_name !== userInfo.groups[g].name) : false))
    }
  }
  if(modalGroupsList.length === 0 && tempGroups.length > 0) setModalGroupsList(tempGroups)
  //modalGroupsList is now the list of all groups except groups this user is part of
  
  if(adminGroups && adminGroups.length !== 0 && tempGroups1.length > 0){
    for(let g = 0; g < adminGroups.length; g++){
      tempGroups1 = tempGroups1.filter(ag => (ag ? (ag.name !== adminGroups[g].group_name) : false))
    }
    tempGroups1 = tempGroups1.map((g) => {
      return {
        group_guid: g.guid,
        group_name: g.name,
        group_id: parseName(g.name),
        group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
      }
    })
    if(modalAdminGroupsList.length === 0) setModalAdminGroupsList(modalAdminGroupsList => [...tempGroups1])
  }//modalAdminGroupsList is now the list of all groups except groups this user is an admin over

  const groups = ((data && data.data && data.data[0] && data.data[0].groups? data.data[0].groups : []) || [])
  .map((g) => {
    return {
      group_guid: g.guid,
      group_name: parseName(g.name),
      group_id: g.name,
      group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
    }
  })
  .sort((a, b) => {
    let _a = (a.group_id || '').toLowerCase()
    let _b = (b.group_id || '').toLowerCase()
    return _a > _b ? 1 : -1
  })

  let intersection = []

  for(var i = 0; i < groups.length; i++){
    for(var a = 0; a < adminGroups.length; a++){
      if(groups[i].group_id === adminGroups[a].group_name){
        groups[i].isAdmin = true
        groups[i].name = groups[i].group_id
        intersection.push(groups[i])
      }
    }
  }
  
  
  const getList = () => {
    if(showModal === MEMBEROF) return modalGroupsList
    if(showModal === ADMINGROUP) return modalAdminGroupsList
  }

  if(!user.roles.find((r) => r.role === 'super-admin') && !user.roles.find((r) => r.role === 'admin') ){
    if (intersection.length === 0 && username !== user.username ) {
      return <NoAccess/>
    }
  }

  const handleOnClose = () => {
    setShowModal(null)
    setMemberOfName("")
    setAdminName("")
    setAddingError(false)
    setAddList([])
  }

  const deleteFromList = (name) => {
    setAddList(addList => ([...(addList.filter(t => t.key !== name))]))
  }

  const addToAddList = (item) => {
    let temp = addList
    for(let t = 0; t < temp.length; t++){
      if(temp[t].key === item.name){
        notify({
          body: <center><b>{item.name}</b> is already in the list of groups to add</center>
        })
        return
      }
    }
    temp.push(
      <Chip 
        className={styles.chip}
        key={item.name ? item.name : item.group_name} 
        label={item.name ? item.name : item.group_name} 
        trailingIcon="close" 
        onTrailingIconInteraction={() => deleteFromList(item.name ? item.name : item.group_name)} 
      />
    )
    setAddList(addList => ([...temp]))
    handleChange("")
    setMemberOfName("")
    setAdminName("")
  }

  const handleSubmit = () => {
    if(showModal === ADMINGROUP){
      handleAdminSubmit()
      return
    }
    if(showModal === MEMBEROF){
      handleGroupSubmit()
      return
    }
  }

  const handleGroupSubmit = async () => {
    setPosting(true)
    setAddingError(false)
    logEvent({category: 'UserInfo',action: `adding user to group`})
    let successfulAdds = []
    for(let count = 0; count < addList.length; count++){
      try {
          await axios.post(`/api/groups/${addList[count].key}/users/${username}`)
          await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:addList[count].key, 
            action:'add user', location:'user info'})
          await refetch()
          successfulAdds.push(addList[count].key)
          //remove group from modalGroupsList
          let tempGroupArr = modalGroupsList
          tempGroupArr = tempGroupArr.filter(g => g.name !== addList[count].key)
          setModalGroupsList(modalGroupsList => ([...tempGroupArr]))
      } catch (error) {
        try{
          let groupData = await axios.get(`/api/groups/${addList[count].key}/users`)
          if(groupData.data.data[0].users.find(u => u.username === username)){
            notify({
              body: <center>Error: <b>{userInfo.firstname} {userInfo.lastname}</b> is already a member of <b>{addList[count].key}</b></center>
            })
            console.error(error)
          }
          else{
            throw new Error('Group Does Not Exist')
          }
        }
        catch(error){
          notify({
            body: <center>Error: failed to add user <b>{userInfo.firstname} {userInfo.lastname}</b> as a member of <b>{addList[count].key}</b></center>
          })
          console.error(error)
        }
      }
    }
    setPosting(false)
    if (successfulAdds.length === 1){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as a member of <b>{successfulAdds[0]}</b></center>
      })
    }
    else if(successfulAdds.length === 2){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as a member of <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b></center>
      })
    }
    else if(successfulAdds.length === 3){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as a member of <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b></center>
      })
    }
    else if(successfulAdds.length > 3){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as a member of <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} other groups</b></center>
      })
    }
    handleOnClose()
  }

  const handleAdminSubmit = async () => {
    logEvent({
      category: 'Userinfo',
      action: `add user as admin over groups`
    })
    setPosting(true)
    setAddingError(false)
    let tempName
    let successfulAdds = []
    for(let count = 0; count < addList.length; count++){
      try {
        let tempArray = modalAdminGroupsList
        tempArray = tempArray.filter(g => g.group_name !== addList[count].key)
        tempArray = tempArray.filter((v,i,a)=>a.findIndex(t=>(t.group_name === v.group_name))===i)//dedup
        setModalAdminGroupsList(tempArray)//removes the group from the list of groups to show in the modal
        tempName = addList[count].key
        await axios.post(`/api/admin/permissions/add/${username}/${tempName}`)
        const adminRoles = await axios.get(`/api/users/${username}/roles`)
        let result = adminRoles.data
        if(result.data === undefined){
          await axios.post(`/api/users/${username}/roles`, {role: 'admin'})
        }
        else{
          result = result.data.roles.find(t => t.role === 'admin')
          if(result === undefined){
            await axios.post(`/api/users/${username}/roles`, {role: 'admin'})
            }
        }
        await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:tempName, 
          action:'add admin', location:'user info'})
        await userAdminGroupsRefetch()
        successfulAdds.push(addList[count].key)
      }
      catch (error) {
        try{
          let groupData = await axios.get(`/api/admin/permissions/groups/${addList[count].key}`)
          if(groupData.data.data.admins.find(u => u.username === username)){
            notify({
              body: <center>Error: <b>{userInfo.firstname} {userInfo.lastname}</b> is already an admin over <b>{addList[count].key}</b></center>
            })
            console.error(error)
          }
          else{
            throw new Error('Group Does Not Exist')
          }
        }
        catch(error){
          notify({
            body: <center>Error: failed to add user <b>{userInfo.firstname} {userInfo.lastname}</b> as a member of <b>{addList[count].key}</b></center>
          })
          console.error(error)
        }
      }
    }
    setPosting(false)
    if (successfulAdds.length === 1){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as an admin over <b>{successfulAdds[0]}</b></center>
      })
    }
    else if(successfulAdds.length === 2){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as an admin over <b>{successfulAdds[0]}</b> and <b>{successfulAdds[1]}</b></center>
      })
    }
    else if(successfulAdds.length === 3){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as an admin over <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds[2]}</b></center>
      })
    }
    else if(successfulAdds.length > 3){
      notify({
        body: <center>Added <b>{userInfo.firstname} {userInfo.lastname}</b> as an admin over <b>{successfulAdds[0]}</b>, <b>{successfulAdds[1]}</b>, and <b>{successfulAdds.length-2} other groups</b></center>
      })
    }
    handleOnClose()
  }

  const handleChange = (val) => {
    if (showModal === MEMBEROF) setMemberOfName(val)
    if (showModal === ADMINGROUP) setAdminName(val)
  }

  const getValue = () => {
    if (showModal === MEMBEROF) return memberOfName
    if (showModal === ADMINGROUP) return adminName
    return ""
  }

  const handleAdminDelete = async (ev, group_id, group_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({category: 'UserInfo',action: `delete user as admin from group`})
    setDelUser(group_id)
    let obj = {name:group_id, group_name:group_id}
    let tempArray = modalAdminGroupsList
    tempArray.push(obj)
    setModalAdminGroupsList(modalAdminGroupsList => ([...tempArray]))
    
    const adminDeleted = await axios.delete('/api/admin/permissions', {data: {username: username, group_name: group_id}})
    const adminGroups = await axios.get(`/api/admin/permissions/${adminDeleted.data.data.username}`)
    if(adminGroups.data.data.length === 0){
      await axios.delete(`/api/users/${username}/roles/admin`) 
    }
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:group_id, 
      action:'delete admin', location:'user info'})
    await userAdminGroupsRefetch()
    setDelUser("")
    ev.stopPropagation()
    notify({
      body: <center>Removed <b>{userInfo.firstname} {userInfo.lastname}</b> as an admin over the <b>{group_name}</b> group</center>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteAdmin(e, group_id)
        }
      ],
    })
  }

  const handleUndeleteAdmin = async (ev, group_id) => {
    ev.preventDefault()
    ev.persist()
    logEvent({category: 'UserInfo',action: `undo delete user as admin`})
    const undoUsername = userInfo.username
    const undoGroup = group_id
    const url = `/api/admin/permissions/add/${undoUsername}/${undoGroup}`
    await axios.post(url)
    //here check if they have the admin role and if not, add it
    const adminUndeleteRoles = await axios.get(`/api/users/${undoUsername}/roles`)
    let result = adminUndeleteRoles.data
      if(result.data === undefined){
         await axios.post(`/api/users/${undoUsername}/roles`, {role: 'admin'})
      }
      else{
        result = result.data.roles.find(t => t.role === 'admin')
        if(result === undefined){
          await axios.post(`/api/users/${undoUsername}/roles`, {role: 'admin'})
          }
      }
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:undoUsername, member_group_name:null, group_name:undoGroup, 
      action:'undelete admin', location:'user info'})
    await userAdminGroupsRefetch()
    ev.stopPropagation()
  }

  const handleUserDelete = async (ev, group_id, group_name) => {
    ev.preventDefault()
    ev.persist()
    logEvent({category: 'UserInfo',action: `delete user from group`})
    setDelUser(group_name)
    let obj = {name:group_name, group_name:group_name}
    let tempArray = modalGroupsList
    tempArray.push(obj)
    setModalGroupsList(modalGroupsList => ([...tempArray]))

    await axios.delete(`/api/groups/${group_id}/users/${userInfo.username}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userInfo.username, member_group_name:null, group_name:group_id, 
      action:'delete user', location:'user info'})
    await refetch()
    setDelUser("")
    ev.stopPropagation()
    notify({
      body: <center>Removed <b>{userInfo.firstname} {userInfo.lastname}</b> from <b>{group_id}</b></center>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteUser(e, group_id)
        }
      ],
    })
  }

  const handleUndeleteUser = async (ev, group_id) => {
    ev.preventDefault()
    ev.persist()
    logEvent({category: 'UserInfo',action: `undo delete user`})
    const undoUsername = userInfo.username
    const undoGroup = group_id
    const url = `/api/groups/${undoGroup}/users/${undoUsername}`
    await axios.post(url)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:undoUsername, member_group_name:null, group_name:undoGroup, 
      action:'undelete user', location:'user info'})
    await refetch()
    ev.stopPropagation()
  }

  const navigateGroupInfo = (g) => {
    logEvent({
      category: 'UserInfo',
      action: `navigate from userinfo to groupinfo page`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:username })
    navigate("/admin/groups/" + g.group_id,{ state: {backNav:backNavArray, search:location.state.search}})
  }

  const navigateAdminInfo = (g) => {
    logEvent({
      category: 'UserInfo',
      action: `navigate from userinfo to admininfo page`
    })
    let backNavArray = location.state.backNav
    // if(backNavArray.indexOf(location.pathname) !== -1) backNavArray.splice(backNavArray.indexOf(location.pathname),1)
    backNavArray.push({path:location.pathname, pageName:username})

    navigate("/superadmin/" + g.group_id,{ state: {backNav:backNavArray, search:location.state.search}})
  }

  const viewTab = (index) => {
    logEvent({
      category: 'GroupInfo',
      action: (index === 0 ? 'view members tab' : index === 1 ? 'view memberof tab' : 'view request tab')
    })
    setActiveTab(index)
  }

  const backButton = () => {
    let tempNav = location.state.backNav[location.state.backNav.length-1].path
    let tempArr = location.state.backNav
    tempArr.pop()
    navigate(tempNav,{ state: {backNav:tempArr, search:location.state.search, isBack:true }})
  }

  const handleSubmitDeleteAll = async (e, intersection) => {
    setPosting(true)
    removeAll ? (decommission(e) ) : (removeFromMyGroups(e, intersection))
    setPosting(false)
  }
  const decommission = async (ev) => {
    ev.preventDefault()
    ev.persist()
    setPosting(true)
    logEvent({category: 'UserInfo',action: `delete user from all groups`})
    setDelUser(username)
    try{
      await axios.post(`/api/users/${username}/decommission`, {groupsToDelete:userGroupsState})

      for(let groupCount = 0; groupCount < userGroupsState.length; groupCount++){
        await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:userGroupsState[groupCount].name, 
        action:'delete user decommission', location:'user info'})
      }
      for(let groupCount = 0; groupCount < adminGroupsState.length; groupCount++){
        await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:adminGroupsState[groupCount].group_id, 
        action:'delete admin decommission', location:'user info'})
      }

      await refetch()
      setDelUser("")
      ev.stopPropagation()
      setPosting(false)
      setDeleteAllOpen(false)
      await userAdminGroupsRefetch()
      notify({
        body: <center>Removed <b>{username}</b> from all groups</center>,
        dismissesOnAction: true,
        actions: [
          {
            label: 'Undo',
            className: styles.action,
            onClick: ev => handleUndoDecommission(ev)
          }
        ],
      })
    }
    catch(err){
      console.log(err)
    }
  }

  const handleUndoDecommission = async (e) => {
    let failedGroups = []
    let failedAdmin = []
    for(let i = 0; i < userGroupsState.length; i++){
      try{//group_name is name //group_id is parseName(name)
        await axios.post(`/api/groups/${userGroupsState[i].name}/users/${userOnlyInfo.username}`)
        await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userOnlyInfo.username, member_group_name:null, group_name:userGroupsState[i].name, 
          action:'undelete user', location:'user info'})
      }
      catch(err){
        console.log(err)
        failedGroups.push(userGroupsState[i].name)
      }
    }
    for(let i = 0; i < adminGroupsState.length; i++){
      try{
        await axios.post(`/api/admin/permissions/add/${userOnlyInfo.username}/${adminGroupsState[i].group_id}`)
        await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userOnlyInfo.username, member_group_name:null, group_name:adminGroupsState[i].group_id, 
          action:'undelete admin', location:'user info'})
      }
      catch(err){
        console.log(err)
        failedAdmin.push(adminGroupsState[i].group_id)
      }
    }
    if(failedGroups.length === 0 && failedAdmin.length === 0){
      notify({
        body: <center>Added <b>{username}</b> back to all their groups. This may take a moment to reflect on the page. </center>,
        dismissesOnAction: true,
      })
    }
    else{
      if(failedGroups.length > 0){
        notify({
          body: <center>Failed to add <b>{username}</b> to the following groups: {failedGroups.join(', ')}</center>,
          dismissesOnAction: true,
        })
      }
      if(failedAdmin.length > 0){
        notify({
          body: <center>Failed to add <b>{username}</b> as an admin over the following groups: {failedAdmin.join(', ')}</center>,
          dismissesOnAction: true,
        })
      }
    }
    await refetch()
    await userAdminGroupsRefetch()
  }

  const removeFromMyGroups = async (ev, intersection) => {
    ev.preventDefault()
    ev.persist()
    setPosting(true)
    logEvent({category: 'UserInfo',action: `delete user from all groups under the admin`})
    setDelUser(username)

    await axios.post(`/api/users/${username}/remove/${user.username}`, {intersection: intersection})

    for(let intersectionCount = 0; intersectionCount < intersection.length; intersectionCount++){
      await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:username, member_group_name:null, group_name:intersection[intersectionCount].group_id, 
        action:'delete user remove all', location:'user info'})
    }

    await refetch()
    setDelUser("")
    ev.stopPropagation()
    setPosting(false)
    setDeleteAllOpen(false)
    notify({
      body: <center>Removed <b>{username}</b> from all your groups</center>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: ev => handleUndoRemoveFromMyGroups(ev, intersection)
        }
      ],
    })
  }

  const handleUndoRemoveFromMyGroups = async (ev, intersection) => {
    let failedGroups = []
    if(intersection && intersection.length > 0){
      for(let i = 0; i < intersection.length; i++){
        try{//group_name is name //group_id is parseName(name)
          await axios.post(`/api/groups/${intersection[i].name}/users/${userOnlyInfo.username}`)
          await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userOnlyInfo.username, member_group_name:null, group_name:intersection[i].name, 
            action:'undelete user', location:'user info'})
        }
        catch(err){
          console.log(err)
          failedGroups.push(intersection[i].name)
        }
      }
      if(failedGroups.length > 0){
        notify({
          body: <center>Failed to add <b>{username}</b> to the following groups: {failedGroups.join(', ')}</center>,
          dismissesOnAction: true,
        })
      }
      else{
        notify({
          body: <center>Added <b>{username}</b> back to your groups. This may take a moment to reflect on the page. </center>,
          dismissesOnAction: true,
        })
      }
      await refetch()
    }
  }

  const openDecommissionModal = (isDecommission) => {
    if(isDecommission) setRemoveAll(true)
    else setRemoveAll(false)
    setDeleteAllOpen(true)
  }
  if((!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin'))){
    return(
      <div className={styles.container}>
        <LogoutUser/>
        <Button  icon="arrow_back" onClick={() => backButton()}>
          Back to {location.state.backNav[location.state.backNav.length-1].pageName}
        </Button>
        <div className={styles.header}>
          <h2 className={styles.title}>{(userOnlyInfo.lastname)? userOnlyInfo.firstname + " " + userOnlyInfo.lastname : ""}</h2>
        </div>
        <p className={styles.subTitle}>ID: {userOnlyInfo.username? userOnlyInfo.username : ""}</p>
        <p className={styles.muted}>
          This is {userOnlyInfo.firstname + " " + userOnlyInfo.lastname}'s info page. It shows the groups that {userOnlyInfo.firstname + " " + userOnlyInfo.lastname} is a part of. <br/>
          You can navigate to, add the user to, or delete the user from the groups in which you have admin permissions. 
          <br/>
        </p>
        <h3 className={styles.listHeader}>
          &nbsp;&nbsp;&nbsp;<div style={{marginTop:'10px'}}>Member Of</div>
          {user && user.roles && (user.roles.find((r) => r.role === "super-admin") || (user.roles.find((r) => r.role === "admin"))) && 
            <Button raised onClick={() => setShowModal(MEMBEROF)} disabled={error} className={styles.right}>
              Add Group
            </Button>
          }
          
          {user && user.roles && user.roles.find((r) => r.role === "admin") && intersection && intersection.length > 0 && 
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button raised onClick={() => openDecommissionModal(false)} disabled={error} >
                Remove From My Groups
              </Button>
            </div>
          }
        </h3>
        <GroupsTab
          user={user}
          loading={loading}
          groups={groups}
          error={error}
          handleUserDelete={handleUserDelete}
          notify={notify}
          navigateGroupInfo={navigateGroupInfo}
          delUser={delUser}
        />
        <NewMemberDialog
          open={showModal !== null}
          onClose={handleOnClose}
          onSubmit={handleSubmit}
          onChange={handleChange}
          value={getValue()}
          list={getList()}
          type={showModal}
          posting={posting}
          error={addingError}
          addList={addList}
          addToAddList={addToAddList}
          userPage={true}
        />
        <DeleteAllModal 
          deleteAllOpen={deleteAllOpen}
          setDeleteAllOpen={setDeleteAllOpen}
          onSubmit={handleSubmitDeleteAll}
          list={userGroupsState}
          adminList={adminGroupsState}
          removeAll={removeAll}
          posting={posting}
          setPosting={setPosting}
          intersection={intersection}
        />
      </div>
    )
  }
  else{
    return (
      <div className={styles.container}>
        <LogoutUser/>
        <Button  icon="arrow_back" onClick={() => backButton()}>
          Back to {location.state.backNav[location.state.backNav.length-1].pageName}
        </Button>
        <div className={styles.header}>
          <h2 className={styles.title}>{(userOnlyInfo.lastname)? userOnlyInfo.firstname + " " + userOnlyInfo.lastname : ""}</h2>
        </div>
        <p className={styles.subTitle}>ID: {userOnlyInfo.username? userOnlyInfo.username : ""}</p>
        <p className={styles.muted}>
          This is {userOnlyInfo.firstname + " " + userOnlyInfo.lastname}'s info page. It shows the groups that {userOnlyInfo.firstname + " " + userOnlyInfo.lastname} is a part of. <br/>
          You can navigate to, add the user to, or delete the user from the groups in which you have admin permissions. 
          <br/>
        </p>
          <TabBar className={styles.tab} activeTabIndex={activeTab} onActivate={(e) => viewTab(e.detail.index)}>
            <Tooltip content="See the groups this user is a part of" align="bottom" activateOn="hover" showArrow enterDelay={500}>
              <Tab>
                Groups {!loading && groups && <div className={styles.tabNumber}>{groups.length}</div>}
              </Tab>
            </Tooltip>
            <Tooltip content="See the groups this user is an admin over" align="bottom" activateOn="hover" showArrow enterDelay={500}>
              <Tab>
                Admin Groups {!userAdminGroupsLoading && userAdminGroups && <div className={styles.tabNumber}>{userAdminGroups.length}</div>}
              </Tab>
            </Tooltip>
          </TabBar>
          {activeTab === 0 ? (
            <>
              <h3 className={styles.listHeader}>
                &nbsp;&nbsp;&nbsp;<div style={{marginTop:'10px'}}>Member Of</div>
                {user && user.roles && (user.roles.find((r) => r.role === "super-admin") || (user.roles.find((r) => r.role === "admin"))) && 
                  <Button raised onClick={() => setShowModal(MEMBEROF)} disabled={error} className={styles.right}>
                    Add Group
                  </Button>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;
                {user && user.roles && user.roles.find((r) => r.role === "super-admin") && 
                  <Button raised onClick={() => openDecommissionModal(true)} disabled={error}>
                    Decommission
                  </Button>
                }
                {user && user.roles && user.roles.find((r) => r.role === "admin") && intersection && intersection.length > 0 &&
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button raised onClick={() => openDecommissionModal(false)} disabled={error} >
                      Remove From My Groups
                    </Button>
                  </div>
                }
              </h3>
              <GroupsTab
                user={user}
                loading={loading}
                groups={groups}
                error={error}
                handleUserDelete={handleUserDelete}
                notify={notify}
                navigateGroupInfo={navigateGroupInfo}
                delUser={delUser}
              />
            </>
          ):(
            <>
              <h3 className={styles.listHeader}>
                &nbsp;&nbsp;&nbsp;<div style={{marginTop:'10px'}}>Admin Groups</div>
                {user && user.roles && user.roles.find((r) => r.role === "super-admin") && 
                  <Button raised onClick={() => setShowModal(ADMINGROUP)} disabled={error} className={styles.right}>
                    Add Group
                  </Button>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;
                {user && user.roles && user.roles.find((r) => r.role === "super-admin") && 
                  <Button raised onClick={() => openDecommissionModal(true)} >
                    Decommission
                  </Button>
                }
              </h3>
              <AdminGroupsTab
                user={user}
                loading={userAdminGroupsLoading}
                groups={userAdminGroups}
                error={userAdminGroupsError}
                handleAdminSubmit={handleAdminSubmit}
                handleAdminDelete={handleAdminDelete}
                navigateAdminInfo={navigateAdminInfo}
                notify={notify}
                delUser={delUser}
              />
            </>
          )}
        <NewMemberDialog
          open={showModal !== null}
          onClose={handleOnClose}
          onSubmit={handleSubmit}
          onChange={handleChange}
          value={getValue()}
          list={getList()}
          type={showModal}
          posting={posting}
          error={addingError}
          addList={addList}
          addToAddList={addToAddList}
          userPage={true}
        />
        <DeleteAllModal 
          deleteAllOpen={deleteAllOpen}
          setDeleteAllOpen={setDeleteAllOpen}
          onSubmit={handleSubmitDeleteAll}
          list={userGroupsState}
          adminList={adminGroupsState}
          removeAll={removeAll}
          posting={posting}
          intersection={intersection}
          setPosting={setPosting}
        />
      </div>
    )
  }
}

const GroupsTab = ({user, loading, groups, error, handleUserDelete, notify, navigateGroupInfo, delUser}) => {
  return(
    <div className={loading ? styles.loadingContainer : styles.listContainerGroupsAdmin} style={{height: groups.length > 0 ? groups.length * 80 : 80}}>
      {error ? (
        <p>{error.message}</p>
      ) : !loading ? (
          <VirtualList
            list={groups}
            icon={(g) => 
              (g.isAdmin || (user && user.roles && user.roles.find((r) => r.role === "super-admin"))) 
              ? 'clear'
              : ''
            }
            primaryText={(g) => g.group_name}
            secondaryText={(g) => (g.group_description ? g.group_description : ('ID: ' + g.group_id))}
            onIconClick={async (e, g) => await handleUserDelete(e, g.group_id, g.group_name)}
            onItemClick={(g) => 
              (g.isAdmin || (user && user.roles && user.roles.find((r) => r.role === "super-admin"))) 
              ? navigateGroupInfo(g)
              : 
              notify({
                body: <>Access Denied: You are not an admin over <b>{g.group_id}</b></>,
              })
            }
            tooltipText1="Remove the user from this group"
            itemKey={delUser}
            itemCompare='group_name'
            emptyListMessage='This user is not a member of any groups'
          />
      ) : (
        <CircularProgress/>
      )}
    </div>
  )
}

const AdminGroupsTab = ({user, loading, groups, error, navigateAdminInfo, notify, delUser, handleAdminDelete}) => {
  return(
    <div className={loading ? styles.loadingContainer : styles.listContainerAdmin} style={{height: groups.length > 0 ? groups.length * 80 : 80}}>
      {error ? (
        <p>{error.message}</p>
      ) : !loading ? (
          <VirtualList
            list={groups}
            icon="clear"
            primaryText={(g) => g.group_name}
            secondaryText={(g) => (g.group_description ? g.group_description : ('ID: ' + g.group_id))}
            onItemClick={(g) => navigateAdminInfo(g)}
            onIconClick={async (e, g) => await handleAdminDelete(e, g.group_id, g.group_name)}
            tooltipText1="Delete this user as an admin over this group"
            itemKey={delUser}
            itemCompare='group_id'
            emptyListMessage='This user is not an admin over any groups'
          />
      ) : (
        <CircularProgress/>
      )}
    </div>
  )
}

const DeleteAllModal = ({deleteAllOpen, setDeleteAllOpen, onSubmit, list, adminList, removeAll, posting, intersection, setPosting}) => {
  let thisList
  if(removeAll){
    thisList = list
  }
  else{
    thisList = intersection
  }
  const submitButton = (ev, intersection) => {
    setPosting(true)
    onSubmit(ev, intersection)
  }
  return (
    <Dialog
      open={deleteAllOpen}
      onClose={() => setDeleteAllOpen(false)}
    >
      <div className={styles.addForm}>
        <h3>{removeAll? <div>Decommission</div> : <div>Remove from my groups</div>}</h3><br/>
        <div className={styles.subtitle}>
          Are you sure? 
          <br/>
          {
            thisList && thisList.length > 0 ?
            <div>
              <br/>
              Delete the user from the following groups:<br/>
              {thisList.map((item) => item.name).join(', ')}
            </div>
            :
            <div></div>
          }
          {
            removeAll && adminList.length > 0 ? (
              <div><br/>
                Remove user as admin from the following groups:<br/>
                {adminList.map((item) => item.group_id).join(', ')}
              </div>
            ):(<></>)
          }
        </div>
        <br/>
        <div className={styles.modalButtons}>
          <Button className={styles.cancel} onClick={() => setDeleteAllOpen(false)}>Cancel</Button>
          <Button 
            raised 
            onClick={e => submitButton(e, intersection)} 
            disabled={posting}
          >{posting? 'Deleting . . .' : 'Delete (this may take a moment)'}</Button>
        </div>
      </div>
    </Dialog>
  )
}