import React, { useState, useContext } from "react";
import {logEvent} from '../../utils/_hooks.js'
import { useNavigate } from "@reach/router"
import styles from "./UserMenu.module.css";
import Avatar from '../Avatar'
import { AuthContext } from '../../context/AuthContext';

import {
  Menu,
  MenuSurfaceAnchor,
  MenuItem,
} from '@rmwc/menu';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';

export default function UserMenu() {
  const [open, setOpen] = useState(false);
  const {user} = useContext(AuthContext)
  const history = useNavigate()

  const handleMenuClick = () => {
    logEvent({
      category: 'AppBar',
      action: `clicked username in appBar`
    })
    if (user)
      setOpen(!open)
    else
      window.location.href = "/login"
  }

  const name = user? 
    `${user.firstname.toUpperCase()[0]}. ${user.lastname.toUpperCase()}`
    : ''
  const logout = () => {
    logEvent({category: 'AppBar',action: `logout`})
    window.location.href = "/logout"
  }

  const navigate = (nav) => {
    logEvent({category: 'AppBar',action: `navigate to profile`})
    history(nav)
  }

  return (
    <MenuSurfaceAnchor className={styles.UserMenu}>
      <Menu
        anchorCorner="bottomLeft"
        open={open}
        onClose={()=>setOpen(false)}
        className={styles.menu}
      >
        {user?
              <MenuItem onClick={() => navigate("/profile/")}>
                <div className={styles.profileIcon}>{user.firstname[0] + user.lastname[0]}</div>
                &nbsp;&nbsp;&nbsp;Profile
              </MenuItem>: 
              <></>
            }
        <MenuItem onClick={() => logout()}>
          <i className="material-icons">exit_to_app</i>
          Log Out
        </MenuItem>
      </Menu>

      <div className={styles.UserDetails} onClick={handleMenuClick}>
        {user?
          <>
            <Avatar className={styles.UserIcon} size="medium" user={user}/>
            <span className={styles.UserText}>{name}</span>
          </>
          :
          <>
            <i className={styles.UserIcon + " material-icons"}>account_circle</i>
            <a className={styles.UserText} href="/login">LOG IN</a>
          </>
        }
      </div>
    </MenuSurfaceAnchor>
  );
}