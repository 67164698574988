import React, {useContext} from 'react'
import styles from './Appbar.module.css'
import { Link } from '@reach/router'
import UserMenu from '../UserMenu/UserMenu'
import { AuthContext } from '../../context/AuthContext'
import GlobalSearch from '../GlobalSearch/GlobalSearch'

export default function Appbar({ toggleSidebar, location, navigate }){
  const {user} = useContext(AuthContext)

  return (
    <div className={styles.appbar + (!user? ` ${styles.noSidebar}`:'')}>
        {toggleSidebar && 
          <i
            onClick={() => toggleSidebar(v=>!v)}
            className={"material-icons " + styles.hamburger}
          >menu</i>
        }
        <Link className={styles.headerImg} to="/">
          <BYUIcon />
        </Link>
        <div className={styles.subtitle}>
          <p className={styles.top}>Physics and Astronomy</p>
          <p className={styles.bottom}>Group Manager</p>
        </div>
        {user && user.roles && (user.roles.find((r) => r.role === 'super-admin') || user.roles.find((r) => r.role === 'admin')) ?
          <div className={styles.spacer}> 
            <div><GlobalSearch location={location} navigate={navigate}/></div>
          </div>
        :
          <div className={styles.spacer}/> 
        }
        {user && <UserMenu />}
    </div>
  )
}

const BYUIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.7 40.3"><g fill="#FFF"><path d="M16.6 16.1V9.4c0-.5-.1-.9-.1-.9s.5.1.9.1h5c3.8 0 6.1.6 6.1 4.1 0 2.3-1.4 4.3-5.9 4.3h-6v-.9zm0 15.3v-7.7c0-.5-.1-.9-.1-.9s.5.1.9.1h4.3c3.4 0 7.5 0 7.5 4.7 0 3.5-3 4.7-7 4.7h-4.8c-.5 0-.9.1-.9.1s.1-.5.1-1zM4.4 4.5v32.1c0 1 .1 1.4-.7 1.9-.7.4-1.4.5-2.6.7-.1 0-.1.5 0 .5h26c11 0 15.6-4.3 15.6-11.1 0-5-2.3-8.1-7-9.2-.1 0-.1-.1 0-.1 2.9-.9 5.7-3.1 5.7-8.3 0-7.1-4.8-9.5-15.2-9.5h-25c-.2-.1-.2.3-.1.4 1.2.2 1.9.3 2.6.7.8.5.7.8.7 1.9zM135.3 4.5c0-1-.1-1.4.7-1.9.7-.4 1.5-.5 2.7-.7.1 0 .1-.5 0-.5h-19.2c-.1 0-.1.4 0 .5 1.2.2 1.8.3 2.5.7.7.5.7.8.7 1.9v19.3c0 4.4-2.5 7.8-7.5 7.8s-7.5-3.4-7.5-7.8V4.5c0-1-.1-1.4.7-1.9.7-.4 1.3-.6 2.5-.7.1 0 .1-.5 0-.5H92.8c-.1 0-.1.5 0 .5.7 0 1.1.1 1.5.4.6.5.7 1.1.7 2.2v19c0 9.1 5 16.9 20.2 16.9s20.2-7.8 20.2-16.9v-19zM77.3 39.7c.1 0 .1-.4 0-.5-1.2-.2-1.9-.3-2.7-.7-.7-.5-.7-.8-.7-1.9V24.4L87.2 4.7c.7-1.1 1.2-1.6 1.8-2.1.1-.1.3-.2.5-.3.5-.3.7-.4 1.4-.4.1 0 .2-.5 0-.5H73c-.1 0-.1.5 0 .5.9 0 1.9 0 1.8 1.3-.1 1.2-5.3 8.7-7.1 11.5-.3.4-.5.8-.6 1.3-.1-.5-.4-1-.6-1.3-2.6-3.8-6.9-10-7.1-11.5-.1-1.3.9-1.3 1.8-1.3.1 0 .2-.5 0-.5H42.4c-.1 0-.1.4 0 .5.8.1 1.2 0 2.2.5.1.1.4.2.5.3.6.5 1 1 1.7 2L60 24.3v12.3c0 1 .1 1.4-.7 1.9-.7.4-1.5.5-2.7.7-.1 0-.1.5 0 .5h20.7z"/></g></svg>
  )
}
