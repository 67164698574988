import React, {useState, useContext} from 'react'
import styles from './Admin.module.css'
import {useGet, logEvent} from '../../utils/_hooks'
import {CircularProgress} from '@rmwc/circular-progress'
import {TextField} from '@rmwc/textfield'
import VirtualList from '../../components/VirtualList/VirtualList'
import LogoutUser from '../../components/LogoutUser/LogoutUser'
import parseName from '../../utils/parseName'
import {AuthContext} from '../../context/AuthContext'
import NoAccess from '../NoAccess/NoAccess'
import axios from "axios"
import { Tooltip } from "@rmwc/tooltip"
import "@rmwc/tooltip/tooltip.css"
import { TabBar, Tab } from "@rmwc/tabs"
import "@rmwc/tabs/styles"
import '@rmwc/circular-progress/styles'
import '@rmwc/textfield/styles'
import '@rmwc/dialog/styles'
import '@rmwc/tooltip/tooltip.css'
import { List, CollapsibleList, SimpleListItem } from "@rmwc/list"
import '@material/list/dist/mdc.list.css'
import '@rmwc/list/collapsible-list.css'
import {SnackbarContext} from '../../context/SnackbarContext'


export default function Admin({navigate, location}) {
  const [search, setSearch] = useState('')
  const [searchSet, setSearchSet] = useState(false)
  const {user} = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState(0)
  const [delRequest, setDelRequest] = useState('')
  const [addRequest, setAddRequest] = useState('')
  const [closed, setClosed] = useState(false)
  const {notify} = useContext(SnackbarContext)
  const { data: userData, error: userError, loading: userLoading } = useGet(`/api/users/${user.username}/users`)
  const { data: groupData, error: groupError, loading: groupLoading } = useGet('/api/admin/permissions/' + user.username)
  const { data: requestData, loading: requestLoading, refetch: requestRefetch } = useGet('/api/admin/permissions/' + user.username + '/requests')

  location.state.backNav = []

  if(!searchSet && location.state.search && location.state.isBack){
    setSearch(location.state.search)
    setSearchSet(true)
  }

  let requests  = requestData && requestData.data ? requestData.data : []

  requests = requests.filter(
    (r) =>
      r.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
      r.user.username.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) || 
      (r.user.firstname + ' ' + r.user.lastname).match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
  )

  if(!user || !user.roles || !user.roles.find((r) => r.role === 'super-admin')){
    if(!user.roles.find((r) => r.role === 'admin')) {
      return <NoAccess />
    }
  }

  let groups = ((groupData && groupData.data) || [])
    .map((g) => {
      return {
        group_guid: g.guid,
        group_name: parseName(g.group_name),
        group_id: g.group_name,
        group_description: g.description? g.description.replace(/^{"/,"").replace(/"}$/,"") : g.description
      }
    })
    .filter(
      (g) =>
        g.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
        g.group_id.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
    )
    .filter((v,i,a)=>a.findIndex(t=>(t.group_name === v.group_name))===i)//dedup
    .sort((a, b) => {
      let _a = (a.group_id || '').toLowerCase()
      let _b = (b.group_id || '').toLowerCase()
      return _a > _b ? 1 : -1
    })

  const users = ((userData && userData.data) || [])
    .map((u) => {
      return {
        user_guid: u.guid,
        username: u.username,
        firstname: u.firstname,
        lastname: u.lastname
      }
    })
    .filter(
      (u) =>
        u.username.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) ||
        (u.firstname + " " + u.lastname).match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
    )
    .filter((v,i,a)=>a.findIndex(t=>(t.username === v.username))===i)//dedup

  const viewTab = (index) => {
    logEvent({
      category: 'GroupInfo',
      action: (index === 0 ? 'view members tab' : index === 1 ? 'view memberof tab' : 'view request tab')
    })
    setActiveTab(index)
  }

  const handleRequestDelete = async (ev, userToDelete, group_id) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'Admin',
      action: `delete group access request`
    })
    setDelRequest(userToDelete.username)
    await axios.delete(`/api/groups/${group_id}/requests/${userToDelete.username}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userToDelete.username, member_group_name:null, group_name:group_id, 
      action:'deny request', location:'admin'})
    await requestRefetch()
    setDelRequest("")
    ev.stopPropagation()
    notify({
      body: <center>Denied <b>{userToDelete.firstname} {userToDelete.lastname}</b> access to the group</center>,
      dismissesOnAction: true,
      actions: [
        {
          label: 'Undo',
          className: styles.action,
          onClick: e => handleUndeleteRequest(e, userToDelete.username, group_id)
        }
      ],
    })
  }

  const handleUndeleteRequest = async (ev, username, group_id) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'Admin',
      action: 'undelete group access request'
    })
    const undoGroup = group_id
    const requestUser = username
    await axios.post(`/api/groups/${undoGroup}/requests`, {username: requestUser})
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:requestUser, member_group_name:null, group_name:group_id, 
      action:'undeny request', location:'admin'})
    await requestRefetch()
    ev.stopPropagation()
  }

  const handleRequestApprove = async (ev, userToAdd, group_id) => {
    ev.preventDefault()
    ev.persist()
    logEvent({
      category: 'Admin',
      action: 'approve group access request'
    })
    try{
    setAddRequest(userToAdd.username)
    await axios.post(`/api/groups/${group_id}/users/${userToAdd.username}`)
    await axios.put(`/api/groups/${group_id}/requests/${userToAdd.username}`)
    await axios.delete(`/api/groups/${group_id}/requests/${userToAdd.username}`)
    await axios.post(`/api/events/create`, {logged_in_uuid:user.uuid, member_user_username:userToAdd.username, member_group_name:null, group_name:group_id, 
      action:'approve request', location:'admin'})
    await requestRefetch()
    setAddRequest("")
    ev.stopPropagation()
    notify({
      body: <>Added <b>{userToAdd.firstname} {userToAdd.lastname}</b> as a member to <b>{group_id}</b>.</>,
      dismissesOnAction: true,
    })
    }
    catch(error){
      setAddRequest("")
        ev.stopPropagation()
          const {data} = await axios.get(`/api/groups/${group_id}/users`)
          if(data.data[0].users.find(u => u.username === userToAdd.username)){
            notify({
              body: <>Error: <b>{userToAdd.firstname} {userToAdd.lastname}</b> is already a member of <b>{group_id}</b></>,
              dismissesOnAction: true,
            })
          }
          else{
            notify({
              body: <>Error: Internal Server Error</>,
              dismissesOnAction: true,
            })
          }
    }
  }

  const navRequestInfo = (id) => {
    logEvent({
      category: 'Admin',
      action: `navigate to groupinfo page for requests`
    })
    navigate('groups/' + id,{ state: {backNav:[{path:location.pathname, pageName:'Admin page'}], search:search }})
  }

  return (
    <div className={styles.container}>
      <LogoutUser/>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Admin&nbsp;
        </h1>
        <div className={styles.separator}></div>
        <TextField
          icon={{icon: 'filter_list', className: styles.searchIcons}}
          {...(search
            ? {
                trailingIcon: {
                  icon: 'close',
                  className: styles.searchIcons,
                  tabIndex: 0,
                  onClick: () => setSearch(''),
                },
              }
            : {})}
          label="Filter"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchInput}
        />
      </div>
      <p className={styles.muted}>
        As an admin, you can use this page to administrate over your admin groups and their users. 
        <br/>You can also approve or deny access requests in the Pending Requests section. 
      </p>
      <List>
        <CollapsibleList
          startOpen
          handle={
            <div>
              <Tooltip content="Click to open or collapse the list of pending requests" align="bottom" showArrow enterDelay={200}>
                <SimpleListItem
                  text={!requestLoading ? 
                    <>
                      <h3>&nbsp;Pending Requests&nbsp;</h3>
                      <div className={requests.length === 0 ? styles.noRequestsTabNumber : styles.requestsTabNumber}>{requests.length}</div>
                    </> 
                  : <h3>&nbsp;Pending Requests&nbsp;&nbsp;<CircularProgress size='xsmall'/></h3>}
                  metaIcon="expand_more"
                />
              </Tooltip>
            </div>
          }
          onClose={() => setClosed(true)}
          onOpen={() => setClosed(false)}
        > 
          <div className={styles.collapsible}>
            <div className={styles.requestsHeader}>
              <div>User</div>
              <div>Group</div>
              <div>Approve/Deny</div>
            </div>
            <div 
              className={requestLoading ? styles.loadingContainer : styles.listContainer} 
              style={{height: requests.length > 0 ? (requests.length > 3 ? 168 : requests.length * 48) : 50}}
            >
              {!requestLoading ? 
                <VirtualList 
                  list={requests}
                  page='modal'
                  sortBy="group_name"
                  secondaryText={(r) => `${r.user.firstname} ${r.user.lastname} (${r.user.username})`}
                  middleText={(r) => r.group_name}
                  onItemClick={r => navRequestInfo(r.group_name)}
                  tooltipText1="Delete this user's request"
                  icon="clear"
                  tooltipText2="Grant this user membership in the group"
                  secondaryIcon="check"
                  onIconClick={async (e, r) => await handleRequestDelete(e, r.user, r.group_name)}
                  onSecondaryIconClick={async (e, r) => await handleRequestApprove(e, r.user, r.group_name)}
                  itemKey={delRequest}
                  secondaryItemKey={addRequest}
                  itemCompare="user"
                  emptyListMessage='No pending group access requests'
                /> 
              :(
                <CircularProgress />
              )}
            </div>
          </div>
        </CollapsibleList>
      </List>
      <TabBar className={styles.tab} activeTabIndex={activeTab} onActivate={(e) => viewTab(e.detail.index)}>
        <Tooltip content="See the list of your admin groups" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab>
            Groups {!groupLoading && groups && <div className={groups.length >= 100? styles.tabNumberLarge : styles.tabNumber}>{groups.length}</div>}
          </Tab>
        </Tooltip>
        <Tooltip content="See the list of all users in your admin groups" align="bottom" activateOn="hover" showArrow enterDelay={500}>
          <Tab>
            Users {!userLoading && users && <div className={users.length >= 100? styles.tabNumberLarge : styles.tabNumber}>{users.length}</div>}
          </Tab>
        </Tooltip>
      </TabBar>
      {activeTab === 0 ? (
        <GroupsTab
          loading={groupLoading}
          groups={groups}
          error={groupError}
          navigate={navigate}
          search={search}
          setSearch={setSearch}
          closed={closed}
          location={location}
        />
      ):(
        <UsersTab
          loading={userLoading}
          users={users}
          error={userError}
          navigate={navigate}
          search={search}
          setSearch={setSearch}
          closed={closed}
          location={location}
        />
      )}
    </div>
  )
}

const GroupsTab = ({loading, groups, error, navigate, search, setSearch, closed, location }) => {

  const navAdminInfo = (id) => {
    
    logEvent({
      category: 'Admin',
      action: `navigate to groupinfo page`
    })
    navigate('groups/' + id,{ state: {backNav:[{path:location.pathname, pageName:'Admin page'}], search:search }})
  }

  return (
    <div>
      <p>These are the groups in which you have admin permissions. Click on a group to see its information. </p>
      <div 
        className={loading ? styles.loadingContainer : !closed ? styles.listContainer2 : styles.closedContainer} 
        style={{height: groups.length <= 1 ? 80 : groups.length <= 4 ? groups.length * 80 : 360}}
      >
        {error ? (
          <p>{error.message}</p>
        ) : !loading ? (
          <VirtualList
            list={groups}
            icon='keyboard_arrow_right'
            primaryText={(g) => g.group_name + '  (' + g.group_id + ')'}
            secondaryText={(g) => (g.group_description ? g.group_description : '')}
            onItemClick={(g) => navAdminInfo(g.group_id)}
            tooltipText1="See the info for this group"
            itemKey=''
            itemCompare='group_name'
            emptyListMessage='No Groups Exist'
          />
        ) : (
          <CircularProgress size="xlarge" />
        )}
      </div>
    </div>
  )
}

const UsersTab = ({loading, users, error, navigate, search, setSearch, closed, location}) => {
  
  const navUserInfo = (id) => {
    logEvent({
      category: 'Admin',
      action: `navigate to userinfo page`
    })
    navigate('users/' + id,{ state: {backNav:[{path:location.pathname, pageName:'Admin page'}], search:search }})
  }

  return (
    <div>
      <p>These are the users who are members of the groups in which you have admin permissions. Click on a user to see their information. </p>
      <div 
        className={loading ? styles.loadingContainer : !closed ? styles.listContainer2 : styles.closedContainer} 
        style={{height: users.length <= 1 ? 80 : users.length <= 4 ? users.length * 80 : 360}}
      >
        {error ? (
          <p>{error.message}</p>
        ) : !loading ? (
          <VirtualList
            list={users}
            icon={'keyboard_arrow_right'}
            primaryText={(u) => u.firstname + " " + u.lastname}
            secondaryText={(u) => u.username}
            onItemClick={(u) => navUserInfo(u.username)}
            tooltipText1="See the information for this user"
            itemKey=''
            itemCompare='username'
            emptyListMessage='No Users Exist'
          />
        ) : (
          <CircularProgress size="xlarge" />
        )}
      </div>
    </div>
  )
}