export default (state, action) => {
  switch (action.type) {
    case 'INIT_LOGIN':
      return {
        ...state
      }
    case 'UPDATE_LOGIN_USER':
      return {
        ...state,
        loaded: true,
        user: action.user
      }
    case 'UPDATE_LOGIN_EXPIRATION':
      return {
        ...state,
        loaded: true,
        expiration: action.expiration
      }
    case 'UPDATE_LAST_AUTH':
    return {
      ...state,
      loaded: true,
      lastAuth: action.lastAuth
    }
    default:
      return state
  }
}
