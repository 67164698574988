import React, {useState} from 'react'
import styles from "./NewMemberDialog.module.css"
import VirtualList from "../VirtualList/VirtualList"
import { Dialog } from '@rmwc/dialog'
import '@material/dialog/dist/mdc.dialog.css'
import '@material/button/dist/mdc.button.css'
import '@material/ripple/dist/mdc.ripple.css'
import { Tooltip } from "@rmwc/tooltip"
import "@rmwc/tooltip/tooltip.css"
import { MenuSurfaceAnchor, MenuSurface  } from "@rmwc/menu"
import '@material/menu/dist/mdc.menu.css'
import '@material/menu-surface/dist/mdc.menu-surface.css'
import { CollapsibleList, SimpleListItem } from "@rmwc/list"
import '@material/list/dist/mdc.list.css'
import '@rmwc/list/collapsible-list.css'
import { TextField } from '@rmwc/textfield'
import '@rmwc/textfield/styles';
import { ChipSet } from '@rmwc/chip'
import '@rmwc/chip/styles';
import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

export default function NewMemberDialog({
  open,
  onClose,
  onSubmit,
  onChange,
  value,
  type,
  posting,
  error,
  list,
  addList,
  addToAddList,
  userPage,
  removeSuggestion,
  suggestions
}) {  
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)

  let header = 'Add'
  let label = ''

  if (type === 'USER') {
    header += ' Users'
    label = 'Search Users'
    if(list){
      list = list.filter(
        u =>
        (u.firstname && u.lastname ? (u.firstname + ' ' + u.lastname).match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) : false) ||
        (u.username ? u.username.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) : false)
      )
    }
  }
  if (type === 'GROUP' || type === 'MEMBEROF' || type === 'ADMINGROUP') {
    header += ' Groups'
    label = 'Search Groups'
    if(list){
      list = list.filter(
        (g) =>
        g.name? g.name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
        : 
        g.group_id ? 
          g.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) || 
          g.group_id.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
        :
        g.group_name.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'))
      )
    }
  }
  if (type === 'ADMIN') {
    header += ' Admins'
    label = 'Search Users'
    if(list){
      list = list.filter(
        u =>
        (u.firstname && u.lastname ? (u.firstname + ' ' + u.lastname).match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) : false) ||
        (u.username ? u.username.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) : false)
      )
    }
  }

  const closeModal = () => {
    setSearch('')
    onClose()
    setActive(true)
  }

  const keyPress = (k) => {
    if(k.keyCode === 13){
      let obj = {name: search, username: search, group_name: search, validated: false}
      addToAddList(obj)
      setSearch('')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
    >
      <div className={styles.addForm}>
        <div onClick={() => setActive(false)}>
          <h3>{header}</h3>
          <p className={styles.subtitle}>
            Type the {(type === 'USER' || type === 'ADMIN') ? 'username of the user ' : 'group name of the group '} 
            you want to add, then click on the corresponding item in the list below or hit enter. 
            <br/>
            When you have added all the {(type === 'USER' || type === 'ADMIN') ? 'users ' : 'groups '} 
            you want, click the "Add" button below. 
          </p>
          {addList && addList.length !== 0 ? (
            <ChipSet>
              {addList}
            </ChipSet>
          )
          :<></>
          }
          <br/>
        </div>
        <div className={styles.textContainer} >
          <MenuSurfaceAnchor>
            <MenuSurface anchorCorner='bottomLeft' open={true}>
              {active ?
                <div 
                  className={styles.listBox} 
                  style={{height: search === '' ? 0 : (list && list.length > 0 ? (list && list.length > 3 ? (suggestions && suggestions.length > 0 ? 200 : type === 'USER' ? 200 : 130) : list.length * 48) : 50 ), width:400}}
                >
                  <VirtualList 
                    list={list || []}
                    page='modal'
                    show={search === '' ? false : true}
                    sortBy="name"
                    secondaryText={(i) => ((type === 'USER' || type === 'ADMIN') ? (i.firstname + ' ' + i.lastname + ' (' + i.username + ')') : i.name ? i.name : i.group_name)}
                    onItemClick={item =>{ addToAddList(item); setSearch('') }}
                    itemKey=''
                    itemCompare="name"
                    emptyListMessage={(type === 'USER' || type === 'ADMIN') ? 'No users to show' : search.length === 0 ? '' : 'No groups to show'}
                    emptyOption={true}
                    search={search}
                    type={type}
                  />
                </div>
              :
                <></>
              }
            </MenuSurface>
            <TextField
              onKeyDown={(k) => keyPress(k)}
              label={label}
              className={styles.textArea}
              value={search}
              {...(search
                ? {
                    trailingIcon: {
                      icon: 'close',
                      className: styles.searchIcons,
                      tabIndex: 0,
                      onClick: () => setSearch(''),
                    },
                  }
                : {}
                )
              }
              onClick={() => setActive(true)}
              onChange={ev => {setSearch(ev.target.value); onChange(ev.target.value)}}
            />
          </MenuSurfaceAnchor>
          <br/>
          {search &&
            <div>
              <br/><br/><br/><br/>
            </div>
          }
          {error && 
            <p className={styles.error}>
              <i className={'material-icons ' + styles.errorIcon}>clear</i>
              <span>We noticed you were still typing something. Be sure to select a value from the dropdown or hit the enter key so all items will be added.</span>
            </p>
          }
        </div>
        <div onClick={() => setActive(false)}>
          {type === 'USER' && suggestions?
            <>
              <br/>
              <div className={styles.listBox}>
                <CollapsibleList 
                  startOpen
                  handle={
                    <div>
                      <Tooltip content="Click to open or collapse suggestions list" align="bottom" showArrow enterDelay={200}>
                        <SimpleListItem
                          text={<h3>&nbsp;Suggestions</h3>}
                          metaIcon="expand_more"
                          className={styles.bottomBorder}
                        />
                      </Tooltip>
                    </div>
                  }
                  style={{borderRadius:'10px'}}
                >
                  <div style={{height: suggestions.length === 0 ? 50 : suggestions.length > 0 ? (suggestions.length > 3 ? 168 : suggestions.length * 48) : 0}}>
                    <VirtualList
                      list={suggestions || []}
                      page='modal'
                      sortBy="name"
                      tooltipText1="A suggested member to add to this group"
                      secondaryText={(i) => ((type === 'USER' || type === 'ADMIN') ? (i.firstname + ' ' + i.lastname + ' (' + i.username + ')') : i.name)}
                      onItemClick={item =>{ setSearch(''); removeSuggestion(item); addToAddList(item); onChange('') }}
                      itemKey=''
                      itemCompare="name"
                      emptyListMessage='There are no suggested users'
                    />
                  </div>
                </CollapsibleList>
              </div>
            </>
            :<></>
          }
          <br/>
          <br/>
          <br/>
          <div className={styles.modalButtons}>
            <Button className={styles.cancel} onClick={() => closeModal()}>Cancel</Button>
            <Button 
              raised 
              onClick={onSubmit} 
              disabled={posting || (!addList || addList.length === 0 )}
            >{posting? 'Adding . . .' : 'Add'}</Button>
          </div>
          <br/>
        </div>
      </div>
    </Dialog>
  )
}
