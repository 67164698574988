import React, { useState, useContext } from "react"
import {
  Router,
  Location,
  Redirect,
  createHistory,
  LocationProvider
} from "@reach/router"
import { AuthContext } from './context/AuthContext'
import ReactGA from 'react-ga'

// Pages
import Admin from "./pages/Admin/Admin"
import SuperAdmin from "./pages/SuperAdmin/SuperAdmin"
import AdminInfo from "./pages/AdminInfo/AdminInfo"
import Results from "./pages/Results/Results"
import Login from './pages/Login/Login'
import GroupInfo from "./pages/GroupInfo/GroupInfo"
import Profile from "./pages/Profile/Profile"
import UserInfo from "./pages/UserInfo/UserInfo"

// Components
import Appbar from "./components/Appbar/Appbar"
import Sidebar from "./components/Sidebar/Sidebar"

//Styles
import styles from './App.module.css'
import '@material/button/dist/mdc.button.css'

//Google Analytics
import {logEvent} from './utils/_hooks.js'
ReactGA.initialize('UA-124898679-3', {
  testMode: process.env.NODE_ENV === 'test'
})
  let history = createHistory(window)


//App
export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const {loaded, user} = useContext(AuthContext)

  history.listen( window => {
    ReactGA.pageview(window.location.pathname+ window.location.search);
    logEvent({category:'Nav',action:'page=> ' + window.location.pathname})
    // console.log('page=>',window.location.pathname);
  });
  
  if(!loaded) return null
  
  if(!user ) {
    return (
      <>
        <Appbar />
        <Login />
      </>
    )
  }

  let defaultPage = `/profile`
  if(user && user.roles && user.roles.find((r) => r.role === 'super-admin')){
    defaultPage = `/superadmin`
  }
  else if(user && user.roles && user.roles.find((r) => r.role === 'admin')){
    defaultPage = `/admin`
  }
  
  return (
    <LocationProvider history={history}>
      <Location>
        {({location, navigate}) => (
          <>
            <Appbar toggleSidebar={setSidebarOpen} location={location} navigate={navigate}/>
            <Sidebar open={sidebarOpen} />
            <div className={styles.notSidebar}>
              <div className={styles.contentArea + (sidebarOpen ? ' sidebarOpen' : '')}>
                <Router location={location} navigate={navigate}>
                  <Redirect from="/" to={defaultPage} noThrow />
                  <SuperAdmin path="/superadmin" />
                  <GroupInfo path="/admin/groups/:group_id"/>
                  <Admin path="/admin" />
                  <AdminInfo path="/superadmin/:group_name"/>
                  <Profile path="/profile" />
                  <UserInfo path="/admin/users/:username" />
                  <Results path="/admin/results/:globalSearch" />
                </Router>
              </div>
            </div>
          </>
        )}
      </Location>
    </LocationProvider>
  )
}
